// pfi
export const FETCH_PFIS_INIT = "pfis@FETCH_PFIS_INIT"
export const FETCH_PFIS_SUCCEDED = "pfis@FETCH_PFIS_SUCCEDED"
export const FETCH_PFIS_FAILED = "pfis@FETCH_PFIS_FAILED"

export const CLEAR_PFI = "pfi@CLEAR_PFI"

export const CREATE_PFI_INIT = "pfi@CREATE_PFI_INIT"
export const CREATE_PFI_SUCCEDED = "pfi@CREATE_PFI_SUCCEDED"
export const CREATE_PFI_FAILED = "pfi@CREATE_PFI_FAILED"

export const FETCH_PFI_INIT = "pfi@FETCH_PFI_INIT"
export const FETCH_PFI_SUCCEDED = "pfi@FETCH_PFI_SUCCEDED"
export const FETCH_PFI_FAILED = "pfi@FETCH_PFI_FAILED"

export const EDIT_PFI_INIT = "pfi@EDIT_PFI_INIT"
export const EDIT_PFI_SUCCEDED = "pfi@EDIT_PFI_SUCCEDED"
export const EDIT_PFI_FAILED = "pfi@EDIT_PFI_FAILED"

export const DELETE_PFI_INIT = "pfi@DELETE_PFI_INIT"
export const DELETE_PFI_SUCCEDED = "pfi@DELETE_PFI_SUCCEDED"
export const DELETE_PFI_FAILED = "pfi@DELETE_PFI_FAILED"
