// transfaredFolder
export const FETCH_TRANSFAREDFOLDERS_INIT = "transfaredFolder@FETCH_TRANSFAREDFOLDERS_INIT"
export const FETCH_TRANSFAREDFOLDERS_SUCCEDED = "transfaredFolder@FETCH_TRANSFAREDFOLDERS_SUCCEDED"
export const FETCH_TRANSFAREDFOLDERS_FAILED = "transfaredFolder@FETCH_TRANSFAREDFOLDERS_FAILED"

export const CLEAR_TRANSFAREDFOLDER = "transfaredFolder@CLEAR_TRANSFAREDFOLDER"

export const CREATE_TRANSFAREDFOLDER_INIT = "transfaredFolder@CREATE_TRANSFAREDFOLDER_INIT"
export const CREATE_TRANSFAREDFOLDER_SUCCEDED = "transfaredFolder@CREATE_TRANSFAREDFOLDER_SUCCEDED"
export const CREATE_TRANSFAREDFOLDER_FAILED = "transfaredFolder@CREATE_TRANSFAREDFOLDER_FAILED"

export const FETCH_TRANSFAREDFOLDER_INIT = "transfaredFolder@FETCH_TRANSFAREDFOLDER_INIT"
export const FETCH_TRANSFAREDFOLDER_SUCCEDED = "transfaredFolder@FETCH_TRANSFAREDFOLDER_SUCCEDED"
export const FETCH_TRANSFAREDFOLDER_FAILED = "transfaredFolder@FETCH_TRANSFAREDFOLDER_FAILED"

export const EDIT_TRANSFAREDFOLDER_INIT = "transfaredFolder@EDIT_TRANSFAREDFOLDER_INIT"
export const EDIT_TRANSFAREDFOLDER_SUCCEDED = "transfaredFolder@EDIT_TRANSFAREDFOLDER_SUCCEDED"
export const EDIT_TRANSFAREDFOLDER_FAILED = "transfaredFolder@EDIT_TRANSFAREDFOLDER_FAILED"

export const DELETE_TRANSFAREDFOLDER_INIT = "transfaredFolder@DELETE_TRANSFAREDFOLDER_INIT"
export const DELETE_TRANSFAREDFOLDER_SUCCEDED = "transfaredFolder@DELETE_TRANSFAREDFOLDER_SUCCEDED"
export const DELETE_TRANSFAREDFOLDER_FAILED = "transfaredFolder@DELETE_TRANSFAREDFOLDER_FAILED"

export const TRANSFAREDFOLDERS_STATUS_INIT = "transfaredFolder@TRANSFAREDFOLDERS_STATUS_INIT"
export const TRANSFAREDFOLDERS_STATUS_SUCCEDED = "transfaredFolder@TRANSFAREDFOLDERS_STATUS_SUCCEDED"
export const TRANSFAREDFOLDERS_STATUS_FAILED = "transfaredFolder@TRANSFAREDFOLDERS_STATUS_FAILED"



export const TRANSFAREDFOLDERBYID_INIT = "transfaredFolder@TRANSFAREDFOLDERBYID_INIT"
export const TRANSFAREDFOLDERBYID_SUCCEDED = "transfaredFolder@TRANSFAREDFOLDERBYID_SUCCEDED"
export const TRANSFAREDFOLDERBYID_FAILED = "transfaredFolder@TRANSFAREDFOLDERBYID_FAILED"

export const TRANSFAREDFOLDER_STATUS = "transfaredFolder@TRANSFAREDFOLDERSTATUS" 