
import { HTTP_METHODS } from "./../../../../../constants"
import { makeCall } from "./../../../../../helpers"
import { store } from "./../../../../../configureStore"


import _ from "lodash"
import { isRLTLang } from "../../../../../i18n"
const FETCH_OPTIONS_ENDPOINT = "/api/support_assessment_tools"


const formatOptions = (options) => options.map((option) => (
  { label: isRLTLang() ? option.labelAr : option.labelFr, value: option.id }))

export const filesassessementToolUIHelper = (callback, evaluationLabel) => {
  let endpoint = FETCH_OPTIONS_ENDPOINT
  const { token } = store.getState().common.auth || {}
  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, endpoint, {}, { 'Authorization': `Bearer ${token.access}` }, { active: 1, id: evaluationLabel })
      .then(resp => {
        let result = {}

        result = resp.data.results[0].supportFilesDetails
        const labelFr = resp.data.results[0].labelFr
        const resultList = []
        if (evaluationLabel)
          resp.data.results[0].supportFilesDetails.map(el => {
            const obj = {
              labelFr,
              ...el
            }
            resultList.push(obj)
          })
        resolve(callback(resultList))

      }).catch(err => reject(err.response))

  )
} 