import _ from "lodash"

import { HTTP_METHODS } from "./../../../../../constants"
import { makeExternalCall } from "./../../../../../helpers"
import { isRLTLang } from "../../../../../i18n"


const FETCH_OPTIONS_ENDPOINT = "/api/pa/options/list?list_number=6"

const formatOptions = (options) => options.map((option) => (
  { label: isRLTLang() ? option.valueAr : option.valueFr, value: option.rank }))


export const familyEconomicSituationsUIHelper = (callback, keyword) => {

  let endpoint = FETCH_OPTIONS_ENDPOINT
    let query = {}
    if (!_.isEmpty(keyword)) {
      query = { q: keyword }
    }
  return new Promise((resolve, reject) =>
  makeExternalCall(HTTP_METHODS.GET, endpoint, {}, {}, query)
  .then(resp => {
    let result = {}
    if (_.isPlainObject(resp.data)) {
      result = [resp.data]
    } else {
      result = resp.data
      console.log(result)
    }
    resolve(callback(formatOptions(result)))
  })
  .catch(err => reject(err.response))
)
  
}





/* export const FAMILY_ECONOMIC_SITUATIONS = { 
    1: "FAMILY_ECONOMIC_SITUATIONS.POOR_FAMILY_INDICATION",
    2: "FAMILY_ECONOMIC_SITUATIONS.LIMITED_INCOME_INDICATION",
    3: "FAMILY_ECONOMIC_SITUATIONS.MEDIUM_CAPABILITIES_INDICATION",
    4: "FAMILY_ECONOMIC_SITUATIONS.AFFORDABLE_INDICATION",
  }
  
export const familyEconomicSituationsUIHelper = (intl) =>
    Object.keys(FAMILY_ECONOMIC_SITUATIONS).map((key) => ({
      value: parseInt(key),
      label: intl.formatMessage({ id: FAMILY_ECONOMIC_SITUATIONS[key] }),
    }))
   */
