// skillsEvaluationD2cFolder
export const FETCH_SKILLS_EVALUATIONS_D2C_INIT = "skillsEvaluationsD2cFolder@FETCH_SKILLS_EVALUATIONS_D2C_INIT"
export const FETCH_SKILLS_EVALUATIONS_D2C_SUCCEDED = "skillsEvaluationsD2cFolder@FETCH_SKILLS_EVALUATIONS_D2C_SUCCEDED"
export const FETCH_SKILLS_EVALUATIONS_D2C_FAILED = "skillsEvaluationsD2cFolder@FETCH_SKILLS_EVALUATIONS_D2C_FAILED"

export const CLEAR_SKILLS_EVALUATION_D2C = "skillsEvaluationD2cFolder@CLEAR_SKILLS_EVALUATION_D2C"

export const CREATE_SKILLS_EVALUATION_D2C_INIT = "skillsEvaluationD2cFolder@CREATE_SKILLS_EVALUATION_D2C_INIT"
export const CREATE_SKILLS_EVALUATION_D2C_SUCCEDED = "skillsEvaluationD2cFolder@CREATE_SKILLS_EVALUATION_D2C_SUCCEDED"
export const CREATE_SKILLS_EVALUATION_D2C_FAILED = "skillsEvaluationD2cFolder@CREATE_SKILLS_EVALUATION_D2C_FAILED"

export const FETCH_SKILLS_EVALUATION_D2C_INIT = "skillsEvaluationD2cFolder@FETCH_SKILLS_EVALUATION_D2C_INIT"
export const FETCH_SKILLS_EVALUATION_D2C_SUCCEDED = "skillsEvaluationD2cFolder@FETCH_SKILLS_EVALUATION_D2C_SUCCEDED"
export const FETCH_SKILLS_EVALUATION_D2C_FAILED = "skillsEvaluationD2cFolder@FETCH_SKILLS_EVALUATION_D2C_FAILED"

export const EDIT_SKILLS_EVALUATION_D2C_INIT = "skillsEvaluationD2cFolder@EDIT_SKILLS_EVALUATION_D2C_INIT"
export const EDIT_SKILLS_EVALUATION_D2C_SUCCEDED = "skillsEvaluationD2cFolder@EDIT_SKILLS_EVALUATION_D2C_SUCCEDED"
export const EDIT_SKILLS_EVALUATION_D2C_FAILED = "skillsEvaluationD2cFolder@EDIT_SKILLS_EVALUATION_D2C_FAILED"

export const DEACTIVATE_SKILLS_EVALUATION_D2C_INIT = "skillsEvaluationD2cFolder@DEACTIVATE_SKILLS_EVALUATION_D2C_INIT"
export const DEACTIVATE_SKILLS_EVALUATION_D2C_SUCCEDED = "skillsEvaluationD2cFolder@DEACTIVATE_SKILLS_EVALUATION_D2C_SUCCEDED"
export const DEACTIVATE_SKILLS_EVALUATION_D2C_FAILED = "skillsEvaluationD2cFolder@DEACTIVATE_SKILLS_EVALUATION_D2C_FAILED"

export const ACTIVATE_SKILLS_EVALUATION_D2C_INIT = "skillsEvaluationD2cFolder@ACTIVATE_SKILLS_EVALUATION_D2C_INIT"
export const ACTIVATE_SKILLS_EVALUATION_D2C_SUCCEDED = "skillsEvaluationD2cFolder@ACTIVATE_SKILLS_EVALUATION_D2C_SUCCEDED"
export const ACTIVATE_SKILLS_EVALUATION_D2C_FAILED = "skillsEvaluationD2cFolder@ACTIVATE_SKILLS_EVALUATION_D2C_FAILED"
