
/* import { HTTP_METHODS } from "./../../../../../constants"
import { makeExternalCall } from "./../../../../../helpers"
import { isRLTLang } from "../../../../../i18n"


const FETCH_OPTIONS_ENDPOINT = "/api/pa/options/list?list_number=1" */

/* const formatOptions = (options) => {
  let values = {}
  options.map((option) => {
      values[option.rank] = isRLTLang() ? option.valueAr : option.valueFr
  })
  return values;
}


export const getOptions = (callback, keyword) => {

  let endpoint = FETCH_OPTIONS_ENDPOINT
  let query = {}
  if (!_.isEmpty(keyword)) {
    query = { q: keyword }
  }

  return new Promise((resolve, reject) =>
    makeExternalCall(HTTP_METHODS.GET, endpoint, {}, {}, query)
      .then(resp => {
        let result = {}
        if (_.isPlainObject(resp.data)) {
          result = [resp.data]
        } else {
          result = resp.data
        }
        resolve(callback(formatOptions(result)))
      })
      .catch(err => reject(err.response))
  )
}
 */


// export const IN_DEPTH_INTERVIEW = 7
// export const INDIVIDUAL_INTERVIEW = 2
 export const COLLECTIVE_WORKSHOP_OR_PROJECT_INFORMATION = 3
// export const VISIT = 4
// export const MEDIATION = 5
export const INTERVIEW = 1
export const ADMISSION = 2
// export const FIRST_INTERVIEW = 1
// export const INTEGRATION_PHASE = 8






export const INTERVIEW_MAINTENANCE = 1
export const ADMISSIONS = 2
export const TEACHING_TRAINING = 3
export const ACCOMPANIEMENT = 4
export const VISITS_EVENTS = 5
export const OFFICE_TASKS = 6
export const PEDAGOGICAL_MEETING = 7
export const ORIENTATION = 8
export const OTHERS = 9


export const NATURE_LIST = {
  [INTERVIEW_MAINTENANCE]: "EVENT.NATURE_LIST.INTERVIEW_MAINTENANCE",
  [ADMISSIONS]: "EVENT.NATURE_LIST.ADMISSIONS",
  [TEACHING_TRAINING]: "EVENT.NATURE_LIST.TRAINING",
  [ACCOMPANIEMENT]: "EVENT.NATURE_LIST.ACCOMPANIEMENT",
  [VISITS_EVENTS]: "EVENT.NATURE_LIST.VISITS_EVENTS",
  [OFFICE_TASKS]: "EVENT.NATURE_LIST.OFFICE_TASKS",
  [PEDAGOGICAL_MEETING]: "EVENT.NATURE_LIST.PEDAGOGICAL_MEETING",
  [ORIENTATION]: "EVENT.NATURE_LIST.ORIENTATION",
  [OTHERS]: "EVENT.NATURE_LIST.OTHERS"

}

export const NATURE_GROUP = {
  3: "EVENT.NATURE_LIST.INTERVIEW_MAINTENANCE",
}

export const DEFAULT_NATURE_GROUP_VALUE = 3

export const DEFAULT_NATURE_VALUE = 1

export const NATURE_CODE_COLOR = {
  1: "#3699FF",
  2: "#8950FC",
  3: "#1BC5BD",
  4: "#FFA800",
  5: "#181C32",
  6: "#20c997",
  7: "#0dcaf0",
  8: "#3699FF",
}

export const NATURE_COLOR = {
  1: "primary",
  2: "info",
  3: "success",
  4: "warning",
  5: "dark",
  6: "teal",
  7: "cyan",
  8: "info",
}


/* const formatOptionss = (options) => options.map((option) => (
  { label: isRLTLang() ? option.valueAr : option.valueFr, value: option.rank }))


export const eventNatureUIHelper = (callback, keyword) => {

  let endpoint = FETCH_OPTIONS_ENDPOINT
  let query = {}
  if (!_.isEmpty(keyword)) {
    query = { q: keyword }
  }

  return new Promise((resolve, reject) =>
    makeExternalCall(HTTP_METHODS.GET, endpoint, {}, {}, query)
      .then(resp => {
        let result = {}
        if (_.isPlainObject(resp.data)) {
          result = [resp.data]
        } else {
          result = resp.data
        }
        resolve(callback(formatOptionss(result)))
      })
      .catch(err => reject(err.response))
  )
} */


export const eventNatureListUIHelper = (intl) =>
  Object.keys(NATURE_LIST).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: NATURE_LIST[key] }),
  }))

export const eventNatureUIHelper = (intl) =>
  Object.keys(NATURE_LIST).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: NATURE_LIST[key] }),
  })
  )

export const eventGroupNatureUIHelper = (intl) =>
  Object.keys(NATURE_GROUP).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: NATURE_GROUP[key] }),
  }))
