export const INITIAL = 1
export const INTERMEDIATE = 2
export const FINAL = 3
export const INTEGRATION = 4


export const SUPPORT_ASSESSMENT_TYPE = {
    [INITIAL]: "SUPPORT.ASSESSEMNT.TYPE.INITIAL",
    [INTERMEDIATE]: "SUPPORT.ASSESSEMNT.TYPE.INTERMEDIATE",
    [FINAL]: "SUPPORT.ASSESSEMNT.TYPE.FINAL",
    [INTEGRATION]: "SUPPORT.ASSESSEMNT.TYPE.INTEGRATION",


}

export const supportAssessementTypeUIHelper = (intl) =>
    Object.keys(SUPPORT_ASSESSMENT_TYPE).map((key) => ({
        value: parseInt(key),
       label:  intl.formatMessage({ id: SUPPORT_ASSESSMENT_TYPE[key] })
    }))