export const MATIERESKILL = [
    { value: 1, label: "SUPPORT_EDUCATIONEL.MATIERE_SKILL.MATIERE" },
    { value: 2, label: "SUPPORT_EDUCATIONEL.MATIERE_SKILL.SKILL" },
  ]
  
  export const skillMatUIHelper = (intl) =>
    MATIERESKILL.map((value) => ({
      ...value,
      label: intl.formatMessage({ id: value.label }),
    }))
  