import _ from "lodash"

import { HTTP_METHODS } from "../../../../../constants"
import { makeExternalCall } from "../../../../../helpers"
import { isRLTLang } from "../../../../../i18n"


const FETCH_OPTIONS_ENDPOINT = "/api/pa/options/list?list_number=12"

const formatOptions = (options) => options.map((option) => (
  { label: isRLTLang() ? option.valueAr : option.valueFr, value: option.rank }))


export const orientationSituationTypeFolderUIHelper = (callback, keyword) => {

  let endpoint = FETCH_OPTIONS_ENDPOINT
  let query = {}
  if (!_.isEmpty(keyword)) {
    query = { q: keyword }
  }

  return new Promise((resolve, reject) =>
    makeExternalCall(HTTP_METHODS.GET, endpoint, {}, {}, query)
      .then(resp => {
        let result = {}
        if (_.isPlainObject(resp.data)) {
          result = [resp.data]
        } else {
          result = resp.data
        }
        resolve(callback(formatOptions(result)))
      })
      .catch(err => reject(err.response))
  )
}

const formatOrientationSituationTypeOptions = (options) => {
  let values = {}
  options.forEach((option) => {
      values[option.rank] = isRLTLang() ? option.valueAr : option.valueFr
  })
  return values;
}


export const getOrientationSituationTypeOptions = (callback, keyword) => {

let endpoint = FETCH_OPTIONS_ENDPOINT
let query = {}
if (!_.isEmpty(keyword)) {
  query = { q: keyword }
}

return new Promise((resolve, reject) =>
  makeExternalCall(HTTP_METHODS.GET, endpoint, {}, {}, query)
    .then(resp => {
      let result = {}
      if (_.isPlainObject(resp.data)) {
        result = [resp.data]
      } else {
        result = resp.data
      }
      resolve(callback(formatOrientationSituationTypeOptions(result)))
    })
    .catch(err => reject(err.response))
)
  }


  export const TRAINING_INDICATION = 1
  export const OCCUPATIONAL_INTEGRATION_INDICATION = 2
  export const OTHER_INDICATION = 3
  export const GO_BACK_SCHOOL_INDICATION = 4
  export const PROFESSIONAL_TRAINING = 5
  export const PROFESSIONAL_LEARNING = 6
  export const SOCIAL_AFFAIRS_STRUCTURE = 7
  
  export const TYPES_SITUATION = { 
    [TRAINING_INDICATION]: "ORIENTATION.TYPE_SITUATION.TRAINING_INDICATION",
    [OCCUPATIONAL_INTEGRATION_INDICATION]: "ORIENTATION.TYPE_SITUATION.OCCUPATIONAL_INTEGRATION_INDICATION",
    [OTHER_INDICATION]: "ORIENTATION.TYPE_SITUATION.OTHER_INDICATION" ,
    [GO_BACK_SCHOOL_INDICATION]: "ORIENTATION.TYPE_SITUATION.GO_BAK_TO_SCHOOL" ,
    [PROFESSIONAL_TRAINING]: "ORIENTATION.TYPE_SITUATION.PROFESSIONAL_TRAINING" ,
    [PROFESSIONAL_LEARNING]: "ORIENTATION.TYPE_SITUATION.PROFESSIONAL_LEARNING" ,
    [SOCIAL_AFFAIRS_STRUCTURE]: "ORIENTATION.TYPE_SITUATION.SOCIAL_AFFAIRS_STRUCTURE" ,
  }

/* export const orientationSituationTypeUIHelper = (intl) =>
  Object.keys(TYPES_SITUATION).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: TYPES_SITUATION[key] }),
  })) */
