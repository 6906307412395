import { ACTIONS } from "./../constants"


const initialState = { 
  transfaredFolders: [], 
  transfaredFolder: {}, 
  transfared : {},
  totalSize: 0,
  isFetching: false, 
  isLoading: false,
  hasMore: true, 
  success: {
    isCreated: false,
    isUpdated: false,
    isDeleted: false
    
  },
  statusCase :"",
  successStatus : {
    isAccepted: false,
    isRefused: false,
    isPending: false
  },
  error: null
}


export default (state = initialState , action) => {
  const { payload, type } = action
  switch (type) {

    case ACTIONS.CLEAR_TRANSFAREDFOLDER : {
      return { ...state, success: initialState.success,successStatus : initialState.successStatus, error: null, isFetching: false, isLoading: false }
    }

    case ACTIONS.FETCH_TRANSFAREDFOLDERS_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_TRANSFAREDFOLDERS_SUCCEDED : {
      const { count, results } = payload
      return { ...state, totalSize: count, transfaredFolders: results, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_TRANSFAREDFOLDERS_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }



    case ACTIONS.FETCH_SOCIAL_FENANCIAL_SITUATION_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_SOCIAL_FENANCIAL_SITUATION_SUCCEDED : {
      const { count, results } = payload
      return { ...state, totalSize: count, social: results, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_SOCIAL_FENANCIAL_SITUATION_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    
    case ACTIONS.CREATE_TRANSFAREDFOLDER_INIT : {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.CREATE_TRANSFAREDFOLDER_SUCCEDED : {
      return { ...state, success: { ...state.success, isCreated: true }, isLoading: false, error: null }
    }
    case ACTIONS.CREATE_TRANSFAREDFOLDER_FAILED : {
      return { ...state, error: payload, isLoading: false, success: false }
    }

    case ACTIONS.EDIT_TRANSFAREDFOLDER_INIT : {      
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.EDIT_TRANSFAREDFOLDER_SUCCEDED : {
      return { ...state, success: { ...state.success, isUpdated: true }, isLoading: false, error: null }
    }
    case ACTIONS.EDIT_TRANSFAREDFOLDER_FAILED : {
      return { ...state, error: payload, isLoading: false, success: false }
    }

    case ACTIONS.TRANSFAREDFOLDERS_STATUS_INIT : {      
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.TRANSFAREDFOLDERS_STATUS_SUCCEDED : {
      return { ...state, success: { ...state.success, isUpdated: true },  successStatus: { isAccepted: state.statusCase === "isAccepted" ? true : false ,isRefused:state.statusCase === "isRefused" ? true : false ,isPending : state.statusCase === "isPending" ? true : false  }, isLoading: false, error: null }
    }
    case ACTIONS.TRANSFAREDFOLDERS_STATUS_FAILED : {
      return { ...state, error: payload, isLoading: false, success: false }
    }


    case ACTIONS.TRANSFAREDFOLDER_STATUS : {
      return { ...state, statusCase: payload}
    }
    
    


    case ACTIONS.TRANSFAREDFOLDERBYID_INIT : {      
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.TRANSFAREDFOLDERBYID_SUCCEDED : {
      return { ...state, transfared : payload , success: { ...state.success, isUpdated: true }, isLoading: false, error: null }
    }
    case ACTIONS.TRANSFAREDFOLDERBYID_FAILED : {
      return { ...state, error: payload, isLoading: false, success: false }
    }

    


 
    case ACTIONS.FETCH_TRANSFAREDFOLDER_INIT : {
      return { ...state, isLoading: true, transfaredFolder: null, error: null }
    }
    case ACTIONS.FETCH_TRANSFAREDFOLDER_SUCCEDED : {
      return { ...state, transfaredFolder: payload, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_TRANSFAREDFOLDER_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.DELETE_TRANSFAREDFOLDER_INIT : {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.DELETE_TRANSFAREDFOLDER_SUCCEDED : {
      return { ...state, success: { ...state.success, isDeleted: true }, isLoading: false, error: null }
    }
    case ACTIONS.DELETE_TRANSFAREDFOLDER_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    default: {
      return state
    }
  }
}
