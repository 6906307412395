// classroom
export const FETCH_CLASSROOM_INIT = "classroom@FETCH_CLASSROOM_INIT"
export const FETCH_CLASSROOM_SUCCEDED = "classroom@FETCH_CLASSROOM_SUCCEDED"
export const FETCH_CLASSROOM_FAILED = "classroom@FETCH_CLASSROOM_FAILED"


export const FETCH_CLASSROOMS_INIT = "classroom@FETCH_CLASSROOMS_INIT"
export const FETCH_CLASSROOMS_SUCCEDED = "classroom@FETCH_CLASSROOMS_SUCCEDED"
export const FETCH_CLASSROOMS_FAILED = "classroom@FETCH_CLASSROOMS_FAILED"

export const CLEAR_CLASSROOM = "classroom@CLEAR_CLASSROOM"

export const CREATE_CLASSROOM_INIT = "classroom@CREATE_CLASSROOM_INIT"
export const CREATE_SCLASSROOM_SUCCEDED = "classroom@CREATE_CLASSROOM_SUCCEDED"
export const CREATE_CLASSROOM_FAILED = "classroom@CREATE_CLASSROOM_FAILED"


export const EDIT_CLASSROOM_INIT = "classroom@EDIT_CLASSROOM_INIT"
export const EDIT_CLASSROOM_SUCCEDED = "classroom@EDIT_CLASSROOM_SUCCEDED"
export const EDIT_CLASSROOM_FAILED = "classroom@EDIT_CLASSROOMFAILED"

export const DELETE_CLASSROOM_INIT = "classroom@DELETE_CLASSROOM_INIT"
export const DELETE_CLASSROOM_SUCCEDED = "classroom@DELETE_CLASSROOM_SUCCEDED"
export const DELETE_CLASSROOM_FAILED = "classroom@DELETE_CLASSROOM_FAILED"


export const DEACTIVATE_CLASSROOM_INIT = "classroom@DEACTIVATE_CLASSROOM_INIT"
export const DEACTIVATE_CLASSROOM_SUCCEDED = "classroom@DEACTIVATE_CLASSROOM_SUCCEDED"
export const DEACTIVATE_CLASSROOM_FAILED = "classroom@DEACTIVATE_CLASSROOM_FAILED"

export const ACTIVATE_CLASSROOM_INIT = "classroom@ACTIVATE_CLASSROOM_INIT"
export const ACTIVATE_CLASSROOM_SUCCEDED = "classroom@ACTIVATE_CLASSROOM_SUCCEDED"
export const ACTIVATE_CLASSROOM_FAILED = "classroom@ACTIVATE_CLASSROOM_FAILED"
