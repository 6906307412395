export const TYPE_COMPETENCE= [
    { value : 1, label : 'COMPETENCE.COGNITIF' },
    { value : 2, label : 'COMPETENCE.METHODOLOGIE' },
    { value : 3, label : 'COMPETENCE.PERSONNEL_EMOTIONNEL' },
    { value : 4, label : 'COMPETENCE.SOCIALE_COMMUNICATIONNELLE' }
]

export const COMPETENCE_VALUE = 2

export const CompetenceTypeUIHelper= (intl)=>
    TYPE_COMPETENCE.map((val)=>({
        ...val,
        label:intl.formatMessage({id:val.label})
    }))


  