// contract
export const FETCH_PHASES_INIT = "phases@FETCH_PHASES_INIT"
export const FETCH_PHASES_SUCCEDED = "phases@FETCH_PHASES_SUCCEDED"
export const FETCH_PHASES_FAILED = "phases@FETCH_PHASES_FAILED"

export const FETCH_PHASE_INIT = "phases@FETCH_PHASE_INIT"
export const FETCH_PHASE_SUCCEDED = "phases@FETCH_PHASE_SUCCEDED"
export const FETCH_PHASE_FAILED = "phases@FETCH_PHASE_FAILED"


export const EDIT_PHASE_INIT = "phases@EDIT_PHASE_INIT"
export const EDIT_PHASE_SUCCEDED = "phases@EDIT_PHASE_SUCCEDED"
export const EDIT_PHASE_FAILED = "phases@EDIT_PHASE_FAILED"

export const CLEAR_PHASES = "phases@CLEAR_PHASES"

export const CREATE_CONTRACT_INIT = "contrat@CREATE_CONTRACT_INIT"
export const CREATE_CONTRACT_SUCCEDED = "contrat@CREATE_CONTRACT_SUCCEDED"
export const CREATE_CONTRACT_FAILED = "contrat@CREATE_CONTRACT_FAILED"

export const FETCH_CONTRACT_INIT = "contrat@FETCH_CONTRACT_INIT"
export const FETCH_CONTRACT_SUCCEDED = "contrat@FETCH_CONTRACT_SUCCEDED"
export const FETCH_CONTRACT_FAILED = "contrat@FETCH_CONTRACT_FAILED"

export const EDIT_CONTRACT_INIT = "contrat@EDIT_CONTRACT_INIT"
export const EDIT_CONTRACT_SUCCEDED = "contrat@EDIT_CONTRACT_SUCCEDED"
export const EDIT_CONTRACT_FAILED = "contrat@EDIT_CONTRACT_FAILED"

export const DELETE_CONTRACT_INIT = "contrat@DELETE_CONTRACT_INIT"
export const DELETE_CONTRACT_SUCCEDED = "contrat@DELETE_CONTRACT_SUCCEDED"
export const DELETE_CONTRACT_FAILED = "contrat@DELETE_CONTRACT_FAILED"


