import { ACTIONS } from "../constants"


const initialState = { 
  educational_supports : [],
  educational_support : [],
  totalSize: 0,
  isFetching: false, 
  isLoading: false,
  hasMore: true, 
  success: {
    isDeactivated: false,
    isActivated: false,
    isCreated: false,
    isUpdated: false,
    isDeleted :false,
  },
  error: null
}


export default (state = initialState , action) => {
  const { payload, type } = action
  switch (type) {

    case ACTIONS.CLEAR_EDUCATIONALSUPPORT: {
      return { ...state, success: initialState.success, error: null, isFetching: false, isLoading: false }
    }

    case ACTIONS.FETCH_EDUCATIONALSUPPORTS_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_EDUCATIONALSUPPORTS_SUCCEDED : {
      const { count, results } = payload
      return { ...state, totalSize: count, educational_supports: results, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_EDUCATIONALSUPPORTS_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.CREATE_EDUCATIONALSUPPORT_INIT : {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.CREATE_EDUCATIONALSUPPORT_SUCCEDED : {
      return { ...state, success: { ...state.success, isCreated: true }, isLoading: false, error: null }
    }
    case ACTIONS.CREATE_EDUCATIONALSUPPORT_FAILED : {
      return { ...state, error: payload, isLoading: false }
    }


    case ACTIONS.DEACTIVATE_EDUCATIONALSUPPORT_INIT : {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.DEACTIVATE_EDUCATIONALSUPPORT_SUCCEDED : {
      return { ...state, success: { ...state.success, isDeactivated: true }, isLoading: false, error: null }
    }
    case ACTIONS.DEACTIVATE_EDUCATIONALSUPPORT_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }



    case ACTIONS.ACTIVATE_EDUCATIONALSUPPORT_INIT : { 
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.ACTIVATE_EDUCATIONALSUPPORT_SUCCEDED : {
      return { ...state, success: { ...state.success, isActivated: true }, isLoading: false, error: null }
    }
    case ACTIONS.ACTIVATE_EDUCATIONALSUPPORT_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }


    case ACTIONS.EDIT_EDUCATIONALSUPPORT_INIT: {      
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.EDIT_EDUCATIONALSUPPORT_SUCCEDED : {
      return { ...state, success: { ...state.success, isUpdated: true }, isLoading: false, error: null }
    }
    case ACTIONS.EDIT_EDUCATIONALSUPPORT_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }


 
    case ACTIONS.FETCH_EDUCATIONALSUPPORT_INIT : {
      return { ...state, isLoading: true, success: initialState.success, educational_support: null, error: null }
    }
    case ACTIONS.FETCH_EDUCATIONALSUPPORT_SUCCEDED : {
      return { ...state, educational_support: payload, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_EDUCATIONALSUPPORT_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }



    case ACTIONS.DELETE_EDUCATIONALSUPPORT_INIT : {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.DELETE_EDUCATIONALSUPPORT_SUCCEDED: {
      return { ...state, success: { ...state.success, isDeleted: true }, isLoading: false, error: null }
    }
    case ACTIONS.DELETE_EDUCATIONALSUPPORT_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }
    default: {
      return state
    }
  }
}
