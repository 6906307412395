import {isRLTLang} from "../../../../i18n"
import { HTTP_METHODS } from "./../../../../constants"
import { makeExternalCall } from "./../../../../helpers"

const FETCH_STRUCTURE_ENDPOINT = "/api/pa/all-structures"


const formatStructure = (structures) => structures.map((structure) => 
  ({ label: isRLTLang() ? structure.titleFr : structure.titleAr, value: structure.reference }) )


export const structureUIHelper = (callback=f=>f) => {

  return new Promise((resolve, reject) =>
    makeExternalCall(HTTP_METHODS.GET, FETCH_STRUCTURE_ENDPOINT )
    .then(resp => {
      resolve(callback( formatStructure(resp.data) ))
    })
    .catch(() => reject(callback([])))
  )
}
