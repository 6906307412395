// pfi
export const FETCH_SETTING_PFIS_INIT = "pfi@FETCH_SETTING_PFIS_INIT"
export const FETCH_SETTING_PFIS_SUCCEDED = "pfi@FETCH_SETTING_PFIS_SUCCEDED"
export const FETCH_SETTING_PFIS_FAILED = "pfi@FETCH_SETTING_PFIS_FAILED"

export const CLEAR_SETTING_PFI = "pfi@CLEAR_SETTING_PFI"

export const CREATE_SETTING_PFI_INIT = "pfi@CREATE_SETTING_PFI_INIT"
export const CREATE_SETTING_PFI_SUCCEDED = "pfi@CREATE_SETTING_PFI_SUCCEDED"
export const CREATE_SETTING_PFI_FAILED = "pfi@CREATE_SETTING_PFI_FAILED"

export const FETCH_SETTING_PFI_INIT = "pfi@FETCH_SETTING_PFI_INIT"
export const FETCH_SETTING_PFI_SUCCEDED = "pfi@FETCH_SETTING_PFI_SUCCEDED"
export const FETCH_SETTING_PFI_FAILED = "pfi@FETCH_SETTING_PFI_FAILED"

export const EDIT_SETTING_PFI_INIT = "pfi@EDIT_SETTING_PFI_INIT"
export const EDIT_SETTING_PFI_SUCCEDED = "pfi@EDIT_SETTING_PFI_SUCCEDED"
export const EDIT_SETTING_PFI_FAILED = "pfi@EDIT_SETTING_PFI_FAILED"

export const DELETE_SETTING_PFI_INIT = "pfi@DELETE_SETTING_PFI_INIT"
export const DELETE_SETTING_PFI_SUCCEDED = "pfi@DELETE_SETTING_PFI_SUCCEDED"
export const DELETE_SETTING_PFI_FAILED = "pfi@DELETE_SETTING_PFI_FAILED"
