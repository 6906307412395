// supportAssessementTool
export const FETCH_SUPPORT_ASSESSMENT_TOOLS_INIT = "supportAssessementTool@FETCH_SUPPORT_ASSESSMENT_TOOLS_INIT"
export const FETCH_SUPPORT_ASSESSMENT_TOOLS_SUCCEDED = "supportAssessementTool@FETCH_SUPPORT_ASSESSMENT_TOOLS_SUCCEDED"
export const FETCH_SUPPORT_ASSESSMENT_TOOLS_FAILED = "supportAssessementTool@FETCH_SUPPORT_ASSESSMENT_TOOLS_FAILED"

export const CLEAR_SUPPORT_ASSESSMENT_TOOL = "supportAssessementTool@CLEAR_SUPPORT_ASSESSMENT_TOOL"

export const CREATE_SUPPORT_ASSESSMENT_TOOL_INIT = "supportAssessementTool@CREATE_SUPPORT_ASSESSMENT_TOOL_INIT"
export const CREATE_SUPPORT_ASSESSMENT_TOOL_SUCCEDED = "supportAssessementTool@CREATE_SUPPORT_ASSESSMENT_TOOL_SUCCEDED"
export const CREATE_SUPPORT_ASSESSMENT_TOOL_FAILED = "supportAssessementTool@CREATE_SUPPORT_ASSESSMENT_TOOL_FAILED"

export const FETCH_SUPPORT_ASSESSMENT_TOOL_INIT = "supportAssessementTool@FETCH_SUPPORT_ASSESSMENT_TOOL_INIT"
export const FETCH_SUPPORT_ASSESSMENT_TOOL_SUCCEDED = "supportAssessementTool@FETCH_SUPPORT_ASSESSMENT_TOOL_SUCCEDED"
export const FETCH_SUPPORT_ASSESSMENT_TOOL_FAILED = "supportAssessementTool@FETCH_SUPPORT_ASSESSMENT_TOOL_FAILED"

export const EDIT_SUPPORT_ASSESSMENT_TOOL_INIT = "supportAssessementTool@EDIT_SUPPORT_ASSESSMENT_TOOL_INIT"
export const EDIT_SUPPORT_ASSESSMENT_TOOL_SUCCEDED = "supportAssessementTool@EDIT_SUPPORT_ASSESSMENT_TOOL_SUCCEDED"
export const EDIT_SUPPORT_ASSESSMENT_TOOL_FAILED = "supportAssessementTool@EDIT_SUPPORT_ASSESSMENT_TOOL_FAILED"

export const DEACTIVATE_SUPPORT_ASSESSMENT_TOOL_INIT = "supportAssessementTool@DEACTIVATE_SUPPORT_ASSESSMENT_TOOL_INIT"
export const DEACTIVATE_SUPPORT_ASSESSMENT_TOOL_SUCCEDED = "supportAssessementTool@DEACTIVATE_SUPPORT_ASSESSMENT_TOOL_SUCCEDED"
export const DEACTIVATE_SUPPORT_ASSESSMENT_TOOL_FAILED = "supportAssessementTool@DEACTIVATE_SUPPORT_ASSESSMENT_TOOL_FAILED"

export const ACTIVATE_SUPPORT_ASSESSMENT_TOOL_INIT = "supportAssessementTool@ACTIVATE_SUPPORT_ASSESSMENT_TOOL_INIT"
export const ACTIVATE_SUPPORT_ASSESSMENT_TOOL_SUCCEDED = "supportAssessementTool@ACTIVATE_SUPPORT_ASSESSMENT_TOOL_SUCCEDED"
export const ACTIVATE_SUPPORT_ASSESSMENT_TOOL_FAILED = "supportAssessementTool@ACTIVATE_SUPPORT_ASSESSMENT_TOOL_FAILED"


export const DELETE_SUPPORT_ASSESSMENT_TOOL_INIT = "supportAssessementTool@DELETE_SUPPORT_ASSESSMENT_TOOL_INIT"
export const DELETE_SUPPORT_ASSESSMENT_TOOL_SUCCEDED = "supportAssessementTool@DELETE_SUPPORT_ASSESSMENT_TOOL_SUCCEDED"
export const DELETE_SUPPORT_ASSESSMENT_TOOL_FAILED = "supportAssessementTool@DELETE_SUPPORT_ASSESSMENT_TOOL_FAILED"
