import _ from "lodash"

import { ACTIVATE, DEACTIVATE, VIEW, UPDATE, CREATE, MODULES_PERMISSIONS, DELETE, UNDELETE } from "./../../../../constants"
//import {isRLTLang} from "../../../i18n"

export const PERMISSIONS_LABELS = {
  [ACTIVATE]: "GENERAL.ACTIVATE",
  [DEACTIVATE]: "GENERAL.DEACTIVATE",
  [VIEW]: "GENERAL.VIEW",
  [UPDATE]: "GENERAL.UPDATE",
  [CREATE]: "GENERAL.CREATE",
  [DELETE]: "GENERAL.DELETE",
  [UNDELETE]: "GENERAL.UNDELETE"
}


export const MODULES_LABELS = {
  [MODULES_PERMISSIONS.FOLDER.module]: "PERMISSIONS.FOLDER",
  [MODULES_PERMISSIONS.USER.module]: "PERMISSIONS.USER",
  [MODULES_PERMISSIONS.USER_GROUP.module]: "PERMISSIONS.USER_GROUP",
  [MODULES_PERMISSIONS.ACCESS_REPORT.module]: "PERMISSIONS.ACCESS_REPORT",
  [MODULES_PERMISSIONS.CURSES_PHASES.module]: "PERMISSIONS.CURSES_PHASES",
  [MODULES_PERMISSIONS.FOLDER_GROUP.module]: "PERMISSIONS.FOLDER_GROUP",
  [MODULES_PERMISSIONS.CALANDER_GROUP.module]: "PERMISSIONS.CALANDER_CONSULTANT",
  [MODULES_PERMISSIONS.PARTNERSHIP.module]: "PERMISSIONS.PARTNERSHIP",
  [MODULES_PERMISSIONS.AVAILABILITY_SETTING_STRUCTURE.module]: "PERMISSIONS.AVAILABILITY_SETTING_STRUCTU",
  [MODULES_PERMISSIONS.SERVICE.module]: "PERMISSIONS.SERVICE",
  [MODULES_PERMISSIONS.CLASSROOM.module]: "PERMISSIONS.CLASSROOM",
  [MODULES_PERMISSIONS.TRANSFEREDFOLDER.module]: "PERMISSIONS.TRANSFEREDFOLDER",
  [MODULES_PERMISSIONS.ACTIVITY.module]: "PERMISSIONS.ACTIVITY",
  [MODULES_PERMISSIONS.EDUCATIONAL_SUPPORT.module]: "PERMISSIONS.EDUCATIONAL_SUPPORT",
  [MODULES_PERMISSIONS.SUBJECT_COMPETENCE.module]: "PERMISSIONS.SUBJECT_COMPETENCE",
  [MODULES_PERMISSIONS.TEMPLATES_CONTRAT.module]: "PERMISSIONS.TEMPLATES_CONTRAT",
  [MODULES_PERMISSIONS.SUPPORT_ASSESSMENT_TOOL.module]: "PERMISSIONS.SUPPORT_ASSESSMENT_TOOL",
  [MODULES_PERMISSIONS.AVAILABILITY_SETTING_USER.module]: "PERMISSIONS.AVAILABILITY_SETTING_USER",
  [MODULES_PERMISSIONS.PERIOD_UNAVAILABILITY.module]: "PERMISSION.MY_PERIODS_OF_UNAVAILABILITY",
  };

export const get_all_permissions = () => {
  const all_permissions = [];
  for (let modulePermissionKey in MODULES_PERMISSIONS) {
    if (MODULES_PERMISSIONS[modulePermissionKey].module in MODULES_LABELS) {
      let permission = {
        label: MODULES_LABELS[MODULES_PERMISSIONS[modulePermissionKey].module],
        options: [],
      };

      for (let permissionKey in MODULES_PERMISSIONS[modulePermissionKey]
        .permissions) {
        permission.options.push({
          label: PERMISSIONS_LABELS[permissionKey],
          value:
            MODULES_PERMISSIONS[modulePermissionKey].permissions[permissionKey],
        });
      }

      all_permissions.push(permission);
    }
  }
  return all_permissions;
};

export const permissionUIHelper = (callback, selectedPermissions = []) => {
  const permissions = [];
  for (let modulePermissionKey in MODULES_PERMISSIONS) {
    if (MODULES_PERMISSIONS[modulePermissionKey].module in MODULES_LABELS) {
      let permission = {
        label: MODULES_LABELS[MODULES_PERMISSIONS[modulePermissionKey].module],
        options: [],
      };

      for (let permissionKey in MODULES_PERMISSIONS[modulePermissionKey]
        .permissions) {
        if (
          permissionKey in PERMISSIONS_LABELS &&
          (_.isEmpty(selectedPermissions) ||
            (!_.isEmpty(selectedPermissions) &&
              _.includes(
                selectedPermissions,
                MODULES_PERMISSIONS[modulePermissionKey].permissions[
                  permissionKey
                ]
              )))
        ) {
          permission.options.push({
            label: PERMISSIONS_LABELS[permissionKey],
            value:
              MODULES_PERMISSIONS[modulePermissionKey].permissions[
                permissionKey
              ],
          });
        }
      }
      if (!_.isEmpty(permission.options)) {
        permissions.push(permission);
      }
    }
  }
  callback(permissions);
};

export default _.memoize(permissionUIHelper);
