import _ from "lodash"

import { AR, FR, HTTP_METHODS } from "./../../../../constants"
import { getAttr, makeCall } from "./../../../../helpers"
import { store } from "./../../../../configureStore"
import { isRLTLang } from "../../../../i18n"




const FETCH_OPTIONS_ENDPOINT = "/api/cursus?active=1" 

const formatCursus= (cursus) => cursus.map((cursus_ins) => ({ 
    label: !isRLTLang() ? cursus_ins.labelFr : cursus_ins.labelAr, value: cursus_ins.id}))


export const cursusUIHelper = (callback=f=>f) => {

    const { token } = store.getState().common.auth || {}
  
    return new Promise((resolve, reject) =>
      makeCall(HTTP_METHODS.GET, FETCH_OPTIONS_ENDPOINT, {}, {'Authorization': `Bearer ${token.access}`}, {} )
      .then(resp => resolve(callback(formatCursus( resp.data.results ))))
      .catch(() => reject(callback([])))
    )
  }












