import _ from "lodash"

import { HTTP_METHODS } from "../../../../../constants"
import { makeCall } from "../../../../../helpers"
import { isRLTLang } from "../../../../../i18n"
import { store } from "../../../../../configureStore"


const FETCH_CLASSROMS_ENDPOINT = "/api/classrooms/:param/type-classroom"

const formatOptions = (options) => options.map((option) => (
  { label: option.nameFr, value: option.id }))


export const classroomNameUIHelper = (callback, param) => {
  let endpoint
  if(param) {
    console.log(param)
    if (!_.isInteger(param) || !param){
       callback([])
       return
    }
     endpoint = FETCH_CLASSROMS_ENDPOINT.replace(":param", param)
  }
  else {
    callback([])
    return
  }
 

  const { token } = store.getState().common.auth || {}

  let query = {}

  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, endpoint, {}, {'Authorization': `Bearer ${token.access}`}, query)
      .then(resp => {
        let result = {}
        let dta = resp.data.filter((x)=> x.isActive=== true && x.status===2)
          if (_.isPlainObject(dta)) {
            result = [dta]
          } else {
            result = dta
          }
        resolve(callback(formatOptions(result)))
      })
      .catch(err => reject(err.response))
  )
}
