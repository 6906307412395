// eductaionl support
export const FETCH_EDUCATIONALSUPPORT_INIT = "educationalsupport@FETCH_EDUCATIONALSUPPORT_INIT"
export const FETCH_EDUCATIONALSUPPORT_SUCCEDED = "educationalsupport@FETCH_EDUCATIONALSUPPORT_SUCCEDED"
export const FETCH_EDUCATIONALSUPPORT_FAILED = "educationalsupport@FETCH_EDUCATIONALSUPPORT_FAILED"


export const FETCH_EDUCATIONALSUPPORTS_INIT = "educationalsupport@FETCH_EDUCATIONALSUPPORTS_INIT"
export const FETCH_EDUCATIONALSUPPORTS_SUCCEDED = "educationalsupport@FETCH_EDUCATIONALSUPPORTS_SUCCEDED"
export const FETCH_EDUCATIONALSUPPORTS_FAILED = "educationalsupport@FETCH_EDUCATIONALSUPPORTS_FAILED"

export const CLEAR_EDUCATIONALSUPPORT = "educationalsupport@CLEAR_EDUCATIONALSUPPORT"

export const CREATE_EDUCATIONALSUPPORT_INIT = "educationalsupport@CREATE_EDUCATIONALSUPPORT_INIT"
export const CREATE_EDUCATIONALSUPPORT_SUCCEDED = "educationalsupport@CREATE_EDUCATIONALSUPPORT_SUCCEDED"
export const CREATE_EDUCATIONALSUPPORT_FAILED = "educationalsupport@CREATE_EDUCATIONALSUPPORT_FAILED"


export const EDIT_EDUCATIONALSUPPORT_INIT = "educationalsupport@EDIT_EDUCATIONALSUPPORT_INIT"
export const EDIT_EDUCATIONALSUPPORT_SUCCEDED = "educationalsupport@EDIT_EDUCATIONALSUPPORT_SUCCEDED"
export const EDIT_EDUCATIONALSUPPORT_FAILED = "educationalsupport@EDIT_EDUCATIONALSUPPORT_FAILED"

export const DELETE_EDUCATIONALSUPPORT_INIT = "educationalsupport@DELETE_EDUCATIONALSUPPORT_INIT"
export const DELETE_EDUCATIONALSUPPORT_SUCCEDED = "educationalsupport@DELETE_EDUCATIONALSUPPORT_SUCCEDED"
export const DELETE_EDUCATIONALSUPPORT_FAILED = "educationalsupport@DELETE_EDUCATIONALSUPPORT_FAILED"

export const DEACTIVATE_EDUCATIONALSUPPORT_INIT = "educationalsupport@DEACTIVATE_EDUCATIONALSUPPORT_INIT"
export const DEACTIVATE_EDUCATIONALSUPPORT_SUCCEDED = "educationalsupport@DEACTIVATE_EDUCATIONALSUPPORT_SUCCEDED"
export const DEACTIVATE_EDUCATIONALSUPPORT_FAILED = "educationalsupport@DEACTIVATE_EDUCATIONALSUPPORT_FAILED"

export const ACTIVATE_EDUCATIONALSUPPORT_INIT = "educationalsupport@ACTIVATE_EDUCATIONALSUPPORT_INIT"
export const ACTIVATE_EDUCATIONALSUPPORT_SUCCEDED = "educationalsupport@ACTIVATE_EDUCATIONALSUPPORT_SUCCEDED"
export const ACTIVATE_EDUCATIONALSUPPORT_FAILED = "educationalsupport@ACTIVATE_EDUCATIONALSUPPORT_FAILED"
