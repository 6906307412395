import _ from "lodash"

import { HTTP_METHODS } from "./../../../../constants"
import { makeCall } from "./../../../../helpers"
import { store } from "./../../../../configureStore"
import { isRLTLang } from "../../../../i18n"


const FETCH_GROUPS_ENDPOINT = "/api/groups/all"

const formatGroups = (groups) => groups.map((group) => ({ label: group.name, permissions: _.map(group.displayPermissions, "codename"), value: group.id }) )


export const groupUIHelper = (callback=f=>f) => {

  const { token } = store.getState().common.auth || {}

  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, FETCH_GROUPS_ENDPOINT, {}, {'Authorization': `Bearer  ${token.access}`}, {} )
    .then( resp =>resolve(callback(formatGroups( resp.data ))))
    .catch(() => reject(callback([])))
  )
}



const formatGroupOptions = (options) => {
  let values = {}
  options.forEach((option) => {
    values[option.id] = isRLTLang() ? option.name : option.name
  });
  return values;
}

export const getGroupOptions = (callback, keyword) => {
  const { token } = store.getState().common.auth || {}
  let endpoint = FETCH_GROUPS_ENDPOINT
  let query = {}
  if (!_.isEmpty(keyword)) {
    query = { q: keyword }
  }
  
  return new Promise((resolve, reject) =>
  makeCall(HTTP_METHODS.GET, FETCH_GROUPS_ENDPOINT, {}, {'Authorization': `Bearer  ${token.access}`}, {} )
  .then(resp => {
        let result = {}
        if (_.isPlainObject(resp.data)) {
          result = [resp.data]
        } else {
          result = resp.data
        }
        resolve(callback(formatGroupOptions(result)))
      })
      .catch(err => reject(err.response))
      )
    }