import _ from "lodash";

import { HTTP_METHODS } from "./../../../../../constants";
import { makeExternalCall } from "./../../../../../helpers";
import { isRLTLang } from "../../../../../i18n";

// const FETCH_OPTIONS_ENDPOINT = "/api/pa/options/list?list_number=13";

// const formatOptions = (options) =>
//   options.map((option) => ({
//     label: isRLTLang() ? option.valueAr : option.valueFr,
//     value: option.rank,
//   }));

// export const exitCourseTypeUIHelper = (callback, keyword) => {
//   let endpoint = FETCH_OPTIONS_ENDPOINT;
//   let query = {};
//   if (!_.isEmpty(keyword)) {
//     query = { q: keyword };
//   }

//   return new Promise((resolve, reject) =>
//     makeExternalCall(HTTP_METHODS.GET, endpoint, {}, {}, query)
//       .then((resp) => {
//         let result = {};
//         if (_.isPlainObject(resp.data)) {
//           result = [resp.data];
//         } else {
//           result = resp.data;
//         }
//         resolve(callback(formatOptions(result)));
//       })
//       .catch((err) => reject(err.response))
//   );
// };

export const exitCourseTypeUIHelper = (intl) =>
  Object.keys(EXIT_COURSE).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: EXIT_COURSE[key] }),
  }));

export const PASSED_COURSE = 1;
export const FAILED_COURSE = 2;
export const ABANDONMENT = 3;
export const EXIT_COURSE = {
  [PASSED_COURSE]: "FOLDER.STATUS.PASSED_COURSE",
  [FAILED_COURSE]: "FOLDER.STATUS.FAILED_COURSE",
  [ABANDONMENT]: "FOLDER.STATUS.ABANDONMENT",
};



export const EXIT_COURSE_COLOR = {
  [PASSED_COURSE]: "success",
  [FAILED_COURSE]: "danger",
  [ABANDONMENT]: "info",
};

// export const THE_RETURN_TO_THE_INITIAL_TEACHING = 1;
// export const ACCESS_TO_QUALIFYING_TRAINING = 2;
// export const ACCESS_TO_DIRECT_EMPLOYMENT = 3;
// export const ABUNDANT = 4;
// export const EXIT_COURSE = {
//   [THE_RETURN_TO_THE_INITIAL_TEACHING]:
//     "FOLDER.STATUS.THE_RETURN_TO_THE_INITIAL_TEACHING",
//   [ACCESS_TO_QUALIFYING_TRAINING]:
//     "FOLDER.STATUS.ACCESS_TO_QUALIFYING_TRAINING",
//   [ACCESS_TO_DIRECT_EMPLOYMENT]: "FOLDER.STATUS.ACCESS_TO_DIRECT_EMPLOYMENT͘",
//   [ABUNDANT]: "FOLDER.STATUS.ABUNDANT",
// };
