import _ from "lodash"

import { HTTP_METHODS } from "./../../../../../constants"
import { makeCall } from "./../../../../../helpers"
import { isRLTLang } from "../../../../../i18n"
import { store } from "./../../../../../configureStore"


const FETCH_ACTIVITY_ENDPOINT = "/api/activity/:param"

const formatOptions = (options) => options.map((option) => (
  { label: isRLTLang() ? option.fullNameAr : option.fullName , 
    value: option.id  }))

export const activityDetailsUIHelper = (callback,callback1, activity) => {
  let endpoint
 
     endpoint = FETCH_ACTIVITY_ENDPOINT.replace(":param", activity['activity'])
  
  const { token } = store.getState().common.auth || {}

  let query = {}

  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, endpoint, {}, {'Authorization': `Bearer ${token.access}`}, query)
      .then(resp => {
        let result,result1 = {}       
          result = resp.data.trainersDetails
          resolve(callback(formatOptions(result)))
          result1 = resp.data
          resolve(callback1(result1))
      })
.catch(err => reject(err.response))
  )
}
