import _ from "lodash"

//import { HTTP_METHODS } from "../../../../../constants"
import { HTTP_METHODS } from "../../../../constants"
//import { makeExternalCall } from "../../../../../helpers"
import { makeExternalCall } from "../../../../helpers"
//import { isRLTLang } from "../../../../../i18n"
import { isRLTLang } from "../../../../i18n"

const FETCH_OPTIONS_ENDPOINT = "/api/pa/options/list?list_number=23"

const formatOptions = (options) => options.map((option) => (
  { label: isRLTLang() ? option.valueAr : option.valueFr, value: option.rank }))


export const classroomSizeUIHelper = (callback, keyword) => {

  let endpoint = FETCH_OPTIONS_ENDPOINT
  let query = {}
  if (!_.isEmpty(keyword)) {
    query = { q: keyword }
  }

  return new Promise((resolve, reject) =>
    makeExternalCall(HTTP_METHODS.GET, endpoint, {}, {}, query)
      .then(resp => {
        let result = {}
        if (_.isPlainObject(resp.data)) {
          result = [resp.data]
        } else {
          result = resp.data
        }
        resolve(callback(formatOptions(result)))
      })
      .catch(err => reject(err.response))
  )
}

const formatClassroomtOptions = (options) => {
  let values = {}
  options.forEach((option) => {
      values[option.rank] = isRLTLang() ? option.valueAr : option.valueFr
  })
  return values;
}


export const getClassroomSizeOptions = (callback, keyword) => {

  let endpoint = FETCH_OPTIONS_ENDPOINT
  let query = {}
  if (!_.isEmpty(keyword)) {
    query = { q: keyword }
  }
  
  return new Promise((resolve, reject) =>
    makeExternalCall(HTTP_METHODS.GET, endpoint, {}, {}, query)
      .then(resp => {
        let result = {}
        if (_.isPlainObject(resp.data)) {
          result = [resp.data]
        } else {
          result = resp.data
        }
        resolve(callback(formatClassroomtOptions(result)))
      })
      .catch(err => reject(err.response))
  )
    }
  

    export const CLASSROOM_SIZE_CODE_ONE = 1
    export const CLASSROOM_SIZE_CODE_TWO = 2
    export const CLASSROOM_SIZE_CODE_THREE = 3

    
    export const CLASSROOM_SIZE = {
        [CLASSROOM_SIZE_CODE_ONE]: "CLASSROOM.SIZE.SMALL",
        [CLASSROOM_SIZE_CODE_TWO]: "CLASSROOM.SIZE.MEDIUM",
        [CLASSROOM_SIZE_CODE_THREE]: "CLASSROOM.SIZE.BIG"
    }


    export const classroomsSizeUIHelper = (intl) =>
      Object.keys(CLASSROOM_SIZE).map((key) => ({
          value: parseInt(key),
          label: intl.formatMessage({ id: CLASSROOM_SIZE[key] }),
      }))


 
/* export const RESIDENCE_TYPE = {
  1: "FOLDER.RESIDENCE_TYPE.RELATIVES",
  2: "FOLDER.RESIDENCE_TYPE.EXTENDED_FAMILY",
  3: "FOLDER.RESIDENCE_TYPE.FRIENDS",
  4: "FOLDER.RESIDENCE_TYPE.PERSONAL_HOME",
  5: "FOLDER.RESIDENCE_TYPE.SPECIALIZED_INSTITUTION"
}


export const residenceTypeUIHelper = (intl) =>
  Object.keys(RESIDENCE_TYPE).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: RESIDENCE_TYPE[key] }),
  }))
 */