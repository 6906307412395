import _ from "lodash"

import { AR, FR, HTTP_METHODS } from "./../../../../constants"
import { getAttr, makeCall } from "./../../../../helpers"
import { store } from "./../../../../configureStore"


const FETCH_OPTIONS_ENDPOINT = "/api/subjects-competences/options" 

const formatSubjectsCompetences = (subjectsCompetences) => subjectsCompetences.map((subjectCompetence) => 
  ({ 
    label: getAttr(subjectCompetence, { [AR]: "labelAr", [FR]: "labelFr" }, "----") , 
    value: subjectCompetence.id 
  }) 
)


export const subjectSkillUIHelper = (callback, param) => {

  if (!_.isInteger(param) || !param){
    callback([])
    return
  }

  let endpoint = FETCH_OPTIONS_ENDPOINT
  const { token } = store.getState().common.auth || {}

  let query = {}
  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, endpoint, {}, {'Authorization': `Bearer  ${token.access}`}, query)
    .then(resp => {
      let result = {}
      let data = resp.data.filter((x)=> x.type == param)

      if (_.isPlainObject(data)){
        result = [data]
      }else {
        result = data
      }
      resolve(callback(formatSubjectsCompetences( result )))
    })
    .catch(err => reject(err.response))
  )
}
























/////////////////////////////////////
/* import _ from "lodash" 
import { HTTP_METHODS } from "../../../../constants" 
import { makeCall } from "../../../../helpers" 
import { isRLTLang } from "../../../../i18n" 
import { store } from "./../../../../configureStore" 
const FETCH_OPTIONS_ENDPOINT = "/api/subjects-competences" 

 
const formatOptions = (options) => options.map((option) => ( 
{ label: isRLTLang() ? option.labelAr : option.labelFr, value: option.id })) 


export const nameUIHelper = (callback, param=1 ) => { 
  let endpoint = FETCH_OPTIONS_ENDPOINT 

  let query = {} 

  query = { type: param } 


const { token } = store.getState().common.auth || {} 
return new Promise((resolve, reject) => 
  makeCall(HTTP_METHODS.GET, endpoint, {}, {'Authorization': `Bearer ${token.access}`}, query) 
  .then(resp => { 
  let result = {} 
  let dta = resp.data.results.filter((x)=> x.isActive=== true)

  if (_.isPlainObject(dta)) { 
    result = [dta] 
  } else { 
    result = dta 
  } 
    resolve(callback(formatOptions(result)))
  }).catch(err => reject(err.response)) 

  ) 
} 


const formatNameOptions = (options) => {
  let values = {}
  options.forEach((option) => {
      values[option.id] = isRLTLang() ? option.labelAr : option.labelFr
  })
  return values;
}

export const formatNameUIHelper = (callback, param) => { 
let endpoint = FETCH_OPTIONS_ENDPOINT 
let query = {} 
if (!_.isEmpty(param)) {
  query = { q: param }
}
const { token } = store.getState().common.auth || {} 
return new Promise((resolve, reject) => 
  makeCall(HTTP_METHODS.GET, endpoint, {}, {'Authorization': `Bearer ${token.access}`}, query) 
  .then(resp => { 
  let result = {} 
  if (_.isPlainObject(resp.data.results)) { 
    result = [resp.data.results] 
  } else { 
    result = resp.data.results 
  } 
    resolve(callback(formatNameOptions(result))) 
  }).catch(err => reject(err.response)) 

  ) 
}  */