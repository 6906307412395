import { AR, FR, HTTP_METHODS } from "./../../../../constants"
import { makeCall } from "./../../../../helpers"
import { store } from "../../../../configureStore"
const FETCH_OPTIONS_ENDPOINT = "/api/support_assessment_tools"





export const supportAssessmentUIHelper = (params, fetchCallback = f => f, dataCallback = f => f, totalSizeCallBack = f => f, supportAssessmentType) => {
  let endpoint = supportAssessmentType ? `${FETCH_OPTIONS_ENDPOINT}/${supportAssessmentType}/filtered` : FETCH_OPTIONS_ENDPOINT
  const { token } = store.getState().common.auth || {}
  fetchCallback(true)
  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, endpoint, {}, { 'Authorization': `Bearer ${token.access}` }, { active: 1, ...params })
      .then(resp => {
        resolve(dataCallback(resp.data.results))
        fetchCallback(false)
        totalSizeCallBack(resp.data.count)
      })
      .catch(() => reject(dataCallback([])))
  )
}
