import { ACTIVATE, CREATE, UPDATE, DEACTIVATE, VIEW, DELETE, UNDELETE, CLOSE, /*VIEW_UPDATE*/ } from "./Permissions"


export const USER = {
  module: "profile",
  permissions: {
    [VIEW]: "view_profile",
    [CREATE]: "add_profile",
    [UPDATE]: "change_profile",
    [ACTIVATE]: "activate_profile",
    [DEACTIVATE]: "delete_profile",
    [DELETE]: "soft_delete_profile",
    [UNDELETE]: "undelete_profile",
  },
}

export const USER_GROUP = {
  module: "group",
  permissions: {
    [VIEW]: "view_group",
    [CREATE]: "add_group",
    [UPDATE]: "change_group",
    // [ACTIVATE]: "activate_group",
    // [DEACTIVATE]: "delete_group",
    [DELETE]: "delete_group"
  }
}

export const FOLDER_GROUP = {
  module: "folder_group",
  permissions: {
    [VIEW]: "view_foldergroup",
    [CREATE]: "add_foldergroup",
    [UPDATE]: "change_foldergroup",
    [ACTIVATE]: "activate_foldergroup",
    [DEACTIVATE]: "delete_foldergroup",
    [DELETE]: "delete_foldergroup"
  }
}

export const FOLDER = {
  module: "folder",
  permissions: {
    [VIEW]: "view_folder",
    [CREATE]: "add_folder",
    [UPDATE]: "change_folder",
    [ACTIVATE]: "activate_folder",
    [DEACTIVATE]: "delete_folder",
    [DELETE]: "delete_folder"
  }
}


export const CALANDER_GROUP = {
  module: "consultant_calendar",
  permissions: {
    [VIEW]: "view_consultantCalendar",
    [CREATE]: "add_consultantCalendar",
    [UPDATE]: "change_consultantCalendar",
    [ACTIVATE]: "activate_consultantCalendar",
    [DEACTIVATE]: "delete_consultantCalendar",
    [DELETE]: "delete_consultantCalendar"
  }
}




export const TRANSFER_REQUEST = {
  module: "transfer_request",
  permissions: {
    [VIEW]: "view_transfer_request",
    [CREATE]: "add_transfer_request",
    [UPDATE]: "change_transfer_request",
    [DELETE]: "delete_transfer_request",
  }
}

export const EVENT = {
  module: "event",
  permissions: {
    [VIEW]: "view_event",
    [CREATE]: "add_event",
    [UPDATE]: "change_event",
    [DELETE]: "delete_event",
    [CLOSE]: "close_event",
  }
}

export const SKILLS_EVALUATION = {
  module: "level",
  permissions: {
    [VIEW]: "view_level",
    [CREATE]: "add_level",
    [UPDATE]: "change_level",
    [DELETE]: "delete_level",
    [CLOSE]: "close_level",
  }
}
export const PFI = {
  module: "pfi",
  permissions: {
    [VIEW]: "view_pfi",
    [CREATE]: "add_pfi",
    [UPDATE]: "change_pfi",
    [DELETE]: "delete_pfi",
    [CLOSE]: "close_pfi",
  }
}
export const FOLDER_DOCUMENT = {
  module: "folder_document",
  permissions: {
    [VIEW]: "view_folder_document",
    [CREATE]: "add_folder_document",
    [UPDATE]: "change_folder_document",
    [DELETE]: "delete_folder_document"
  }
}

export const PERIOD_UNAVAILABILITY = {
  module: "period_unavailability",
  permissions: {
    [VIEW]: "view_unavailabilityperiod",
    [CREATE]: "add_unavailabilityperiod",
    [UPDATE]: "change_unavailabilityperiod",
    [DELETE]: "delete_unavailabilityperiod"
  }
}

export const AVAILABILITY_SETTING_USER = {
  module: "availability_setting_user",
  permissions: {
    [VIEW]: "view_availability_setting_user",
    [CREATE]: "add_availability_setting_user",
    [UPDATE]: "change_availability_setting_user",
    [DELETE]: "delete_availability_setting_user"
  }
}

export const AVAILABILITY_SETTING_STRUCTURE = {
  module: "availability_setting_structure",
  permissions: {
    [VIEW]: "view_availability_setting_structure",
    [CREATE]: "add_availability_setting_structure",
    [UPDATE]: "change_availability_setting_structure",
    [DELETE]: "delete_availability_setting_structure"
  }
}



export const FAMILY_MEMBER = {
  module: "family_member",
  permissions: {
    [VIEW]: "view_family_member",
    [CREATE]: "add_family_member",
    [UPDATE]: "change_family_member",
    [DELETE]: "delete_family_member"
  }
}

export const SERVICE = {
  module: "service",
  permissions: {
    [VIEW]: "view_service",
    [CREATE]: "add_service",
    [UPDATE]: "change_service",
    [ACTIVATE]: "activate_service",
    [DEACTIVATE]: "delete_service",
    [DELETE]: "delete_service",
  },
}

//CLASSROOM

export const CLASSROOM = {
  module: "classrooms",
  permissions: {
    [VIEW]: "view_classroom",
    [CREATE]: "add_classroom",
    [UPDATE]: "change_classroom",
    [ACTIVATE]: "activate_classroom",
    [DEACTIVATE]: "delete_classroom",
    [DELETE]: "delete_classroom",
    
  },
}


export const EDUCATIONAL_SUPPORT = {
  module: "educational_support",
  permissions: {
    [VIEW]: "view_educationalsupport",
    [CREATE]: "add_educationalsupport",
    [UPDATE]: "change_educationalsupport",
    [ACTIVATE]: "activate_educationalsupport",
    [DEACTIVATE]: "delete_educationalsupport",
    [DELETE]: "delete_educationalsupport",
    
  },
}

export const ASSOCIATE_SERVICE = {
  module: "associate_service",
  permissions: {
    [VIEW]: "view_associate_service",
    [CREATE]: "add_associate_service",
    [UPDATE]: "change_associate_service",
    [ACTIVATE]: "activate_associate_service",
    [DEACTIVATE]: "delete_associate_service",
  },
}

export const PARTNERSHIP = {
  module: "partnership",
  permissions: {
    [VIEW]: "view_partner",
    [CREATE]: "add_partner",
    [UPDATE]: "change_partner",
    [ACTIVATE]: "activate_partner",
    [DEACTIVATE]: "delete_partner",
    [DELETE]: "delete_partner",
  },
}

export const SUBJECT_COMPETENCE = {
  module: "subject_competence",
  permissions: {
    [VIEW]: "view_subjectcompetence",
    [CREATE]: "add_subjectcompetence",
    [UPDATE]: "change_subjectcompetence",
    [ACTIVATE]: "activate_subjectcompetence",
    [DEACTIVATE]: "delete_subjectcompetence",
  },
}

export const EVALUATION_RUBRIC = {
  module: "evaluation_rubric",
  permissions: {
    [VIEW]: "view_evaluationrubric",
    [CREATE]: "add_evaluationrubric",
    [UPDATE]: "change_evaluationrubric",
    [ACTIVATE]: "activate_evaluationrubric",
    [DEACTIVATE]: "delete_evaluationrubric",
    [DELETE]: "delete_evaluationrubric",
  },
}

export const ASSESSMENT_LEVEL = {
  module: "assessment_level",
  permissions: {
    [VIEW]: "view_assessmentlevel",
    [CREATE]: "add_assessmentlevel",
    [UPDATE]: "change_assessmentlevel",
    [ACTIVATE]: "activate_assessmentlevel",
    [DEACTIVATE]: "delete_assessmentlevel",
    [DELETE]: "delete_assessmentlevel",
  },
}

export const KNOWLEDGE_SKILL = {
  module: "knowledge_skill",
  permissions: {
    [VIEW]: "view_knowledgeskill",
    [CREATE]: "add_knowledgeskill",
    [UPDATE]: "change_knowledgeskill",
    [ACTIVATE]: "activate_knowledgeskill",
    [DEACTIVATE]: "delete_knowledgeskill",
    [DELETE]: "delete_knowledgeskill",
  },
}

export const APTITUDE_SKILL = {
  module: "aptitude_skill",
  permissions: {
    [VIEW]: "view_aptitudeskill",
    [CREATE]: "add_aptitudeskill",
    [UPDATE]: "change_aptitudeskill",
    [ACTIVATE]: "activate_aptitudeskill",
    [DEACTIVATE]: "delete_aptitudeskill",
    [DELETE]: "delete_aptitudeskill",
  },
}

export const SKILL = {
  module: "skill",
  permissions: {
    [VIEW]: "view_kill",
    [CREATE]: "add_skill",
    [UPDATE]: "change_skill",
    [ACTIVATE]: "activate_skill",
    [DEACTIVATE]: "delete_skill",
  },
}


export const ASSESSMENT_TOOL = {
  module: "evaluation_tool",
  permissions: {
    [VIEW]: "view_evaluationtool",
    [CREATE]: "add_evaluationtool",
    [UPDATE]: "change_evaluationtool",
    [ACTIVATE]: "activate_evaluationtool",
    [DEACTIVATE]: "delete_evaluationtool",
    [DELETE]: "delete_evaluationtool",
  },
}

export const SUPPORT_ASSESSMENT_TOOL = {
  module: "support_evaluation_tool",
  permissions: {
    [VIEW]: "view_supportassessementtool",
    [CREATE]: "add_supportassessementtool",
    [UPDATE]: "change_supportassessementtool",
    [ACTIVATE]: "activate_supportassessementtool",
    [DEACTIVATE]: "delete_supportassessementtool",
    [DELETE]: "delete_supportassessementtool",
  },
}

export const CURSES_PHASES = {
  module: "curses",
  permissions: {
    [VIEW]: "view_cursus",
    [CREATE]: "add_cursus",
    [UPDATE]: "change_cursus",
    [ACTIVATE]: "activate_cursus",
    [DEACTIVATE]: "delete_cursus",
    [DELETE]: "delete_cursus",
  },
}

export const ORIENTATION = {
  module: "orientation",
  permissions: {
    [VIEW]: "view_orientation",
    [CREATE]: "add_orientation",
    [UPDATE]: "change_orientation",
    [ACTIVATE]: "activate_orientation",
    [DEACTIVATE]: "delete_orientation",
  },
}

export const POST_COURSE_FOLLOWUP = {
  module: "followup",
  permissions: {
    [VIEW]: "view_followup",
    [CREATE]: "add_followup",
    [UPDATE]: "change_followup",
    [ACTIVATE]: "activate_followup",
    [DEACTIVATE]: "delete_followup",
  }
}

export const SCHOOL_DROPOUT = {
  module: "school_dropout",
  permissions: {
    [VIEW]: "view_schooldropout",
    [CREATE]: "add_schooldropout",
    [UPDATE]: "change_schooldropout",
    [ACTIVATE]: "activate_schooldropout",
    [DEACTIVATE]: "delete_schooldropout",
    [DELETE]: "delete_schooldropout",
  },
}
export const TRANSFEREDFOLDER = {
  module: "TransfaredFolder",
  permissions: {
    [VIEW]: "view_TransfaredFolder",
    [CREATE]: "add_TransfaredFolder",
    [UPDATE]: "change_TransfaredFolder",
    // [ACTIVATE]: "activate_TransfaredFolder",
    // [DEACTIVATE]: "delete_TransfaredFolder",
    [DELETE]: "delete_TransfaredFolder",
  },


  
}
export const TEMPLATES_CONTRAT = {
  module: "template_contracts",
  permissions: {
    [VIEW]: "view_templateContracts",
    [CREATE]: "add_templateContracts",
    [UPDATE]: "change_templateContracts",
    [ACTIVATE]: "activate_templateContracts",
    [DEACTIVATE]: "delete_templateContracts",
    [DELETE]: "delete_templateContracts",
  },


  
}
export const ACCESS_REPORT = {
  module: "AccesReport",
  permissions: {
    [VIEW]: "view_accesreport",
    [CREATE]: "add_accesreport",
    [UPDATE]: "change_accesreport",
    [ACTIVATE]: "activate_accesreport",
    [DEACTIVATE]: "delete_accesreport",
  },
}


export const ACTIVITY = {
  module: "activity",
  permissions: {
    [VIEW]: "view_activity",
    [CREATE]: "add_activity",
    [UPDATE]: "change_activity",
    [ACTIVATE]: "activate_activity",
    [DEACTIVATE]: "delete_activity",
    [DELETE]: "delete_activity",
  },
}

