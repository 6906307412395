export const SUBJECT = 1
export const SKILL = 2
export const OTHER = 3



export const TYPE_ACTIVITY = {
    [SUBJECT]: "TYPE.ACTIVITY.SUBJECT",
    [SKILL]: "TYPE.ACTIVITY.SKILL",
    [OTHER]: "TYPE.ACTIVITY.OTHER"
}


export const typeActivityUIHelper = (intl) =>
  Object.keys(TYPE_ACTIVITY).map((key) => ({
      value: parseInt(key),
      label: intl.formatMessage({ id: TYPE_ACTIVITY[key] }),
  }))