export const FOLDERS = "/api/foldersD2c"
export const FOLDER = "/api/foldersD2c/:param"
export const EXPORT_EMPLOYMENT_CONTRACT = "/api/foldersD2c/contract/:param/generate/:id"
export const EXPORT_INTERNAL_RULES = "/api/foldersD2c/rules/:param/generate/:id"
export const EXPORT_FOLDER = "/api/foldersD2c/:param/print"

export const DOWNLOAD_PFI = "/api/foldersD2c/:param/generate-pfi"

export const EXPORT_FOLDERS = "/api/foldersD2c/export"
export const ROUTE_OF_JUSTFIED = "/api/folders/:param/justified"
export const FOLDER_BEING_PROCESSED = "/api/folders/:param/being-processed"

export const FOLDER_APPOINTMENTS_LIST = "/api/folders/:param/appointments"
export const FOLDER_APPOINTMENTS = "/api/appointments"
export const FOLDER_APPOINTMENT = "/api/appointments/:param"



export const CREATE_APPOINTMENT = "/api/appointments"

export const DOCUMENTS = "/api/documents/folder/:param"
export const DOCUMENT = "/api/documents/:param"

export const AVAILABILITIES = "/api/availabilities"

export const USER_APPOINTMENTS_LIST = "/api/appointments/cps"

export const USER_APPOINTMENTS = "/api/folders/:param/appointments/cp"
export const USER_APPOINTMENT = "/api/appointments/:param"

export const FOLDER_ACTIVATE = "/api/foldersD2c/:param/activate"
export const FOLDER_DEACTIVATE = "/api/foldersD2c/:param/deactivate"
export const FOLDER_CANCEL_TRANSFER = "/api/folders/:param/cancel-transfer"

export const EVENT = "/api/event/:param" 
export const EVENTS = "/api/events"

export const FOLDER_DELETE = "/api/folders/:param/delete"
export const FOLDER_UNDELETE = "/api/folders/:param/undelete"

export const FOLDERS_ACTIVATE = "/api/folders/activate"
export const FOLDERS_DEACTIVATE = "/api/folders/deactivate"

export const FOLDERS_UNDELETE = "/api/folders/undelete"
export const FOLDERS_DELETE = "/api/folders/delete"


export const FOLDER_ACADEMIC_PROFESSIONAL_PATH = "/api/folders/:param/academic-professional-path"
export const FOLDER_FAMILY_IDENTIFICATION = "/api/folders/:param/family-information"
export const FOLDER_PARENT_INFORMATION = "/api/folders/:param/family-information/parents"
export const FOLDER_EDIT_PARENT_INFORMATION = "/api/parents/:param"
export const FOLDER_PERSONAL_DATA = "/api/foldersD2c/:param"
export const FOLDER_SOCIAL_AND_PHYSICAL_DATA = "/api/foldersD2c/:param/social-financial-situation" 
export const FOLDER_ACTION_PLAN = "/api/folders/:param/action-plan" 
export const FOLDER_HOWAMI = "/api/folders/:param/beneficiary-description"
export const FOLDER_EXIT = "/api/folders/:param/exit-gu"

export const DOWNLOAD_EVENT = "/api/events/:param/print"

export const DOWNLOAD_SCHEDULE = "/api/folders/:param/schedules"


export const IMPORT_FOLDERS = "/api/folders/import"

export const CPS_HISTORY_LIST = "/api/folders/:param/cps_history"
export const FOLDER_DOCUMENT_DOWNLOAD = "/api/folder/document/:param/download?isGu=queryParam"
