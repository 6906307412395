// eductaionl support
export const FETCH_ACCESS_REPORT_INIT = "access_report@FETCH_ACCESS_REPORT_INIT"
export const FETCH_ACCESS_REPORT_SUCCEDED = "access_report@FETCH_ACCESS_REPORT_SUCCEDED"
export const FETCH_ACCESS_REPORT_FAILED = "access_report@FETCH_ACCESS_REPORT_FAILED"


export const FETCH_ACCESS_REPORTS_INIT = "access_report@FETCH_ACCESS_REPORTS_INIT"
export const FETCH_ACCESS_REPORTS_SUCCEDED = "access_report@FETCH_ACCESS_REPORTS_SUCCEDED"
export const FETCH_ACCESS_REPORTS_FAILED = "access_report@FETCH_ACCESS_REPORTS_FAILED"

export const CLEAR_ACCESS_REPORT = "access_report@CLEAR_ACCESS_REPORT"

export const CREATE_ACCESS_REPORT_INIT = "access_report@CREATE_ACCESS_REPORT_INIT"
export const CREATE_ACCESS_REPORT_SUCCEDED = "access_report@CREATE_ACCESS_REPORT_SUCCEDED"
export const CREATE_ACCESS_REPORT_FAILED = "access_report@CREATE_ACCESS_REPORT_FAILED"


export const EDIT_ACCESS_REPORT_INIT = "access_report@EDIT_ACCESS_REPORT_INIT"
export const EDIT_ACCESS_REPORT_SUCCEDED = "access_report@EDIT_ACCESS_REPORT_SUCCEDED"
export const EDIT_ACCESS_REPORT_FAILED = "access_report@EDIT_ACCESS_REPORT_FAILED"

export const DELETE_ACCESS_REPORT_INIT = "access_report@DELETE_ACCESS_REPORT_INIT"
export const DELETE_ACCESS_REPORT_SUCCEDED = "access_report@DELETE_ACCESS_REPORT_SUCCEDED"
export const DELETE_ACCESS_REPORT_FAILED = "access_report@DELETE_ACCESS_REPORT_FAILED"

export const DEACTIVATE_ACCESS_REPORT_INIT = "access_report@DEACTIVATE_ACCESS_REPORT_INIT"
export const DEACTIVATE_ACCESS_REPORT_SUCCEDED = "access_report@DEACTIVATE_ACCESS_REPORT_SUCCEDED"
export const DEACTIVATE_ACCESS_REPORT_FAILED = "access_report@DEACTIVATE_ACCESS_REPORT_FAILED"

export const ACTIVATE_ACCESS_REPORT_INIT = "access_report@ACTIVATE_ACCESS_REPORT_INIT"
export const ACTIVATE_ACCESS_REPORT_SUCCEDED = "access_report@ACTIVATE_ACCESS_REPORT_SUCCEDED"
export const ACTIVATE_ACCESS_REPORT_FAILED = "access_report@ACTIVATE_ACCESS_REPORT_FAILED"

export const ACCESS_REPORT_RESULTS_INIT = "access_report@ACCESS_REPORT_RESULTS_INIT"
export const ACCESS_REPORT_RESULTS_SUCCEDED = "access_report@ACCESS_REPORT_RESULTS_SUCCEDED"
export const ACCESS_REPORT_RESULTS_FAILED = "access_report@ACCESS_REPORT_RESULTS_FAILED"