import _ from "lodash"

import { HTTP_METHODS } from "./../../../../../constants"
import { makeExternalCall } from "./../../../../../helpers"
import { isRLTLang } from "../../../../../i18n"


const FETCH_OPTIONS_ENDPOINT = "/api/pa/options/list?list_number=3"

const formatOptions = (options) => options.map((option) => (
  { label: isRLTLang() ? option.valueAr : option.valueFr, value: option.rank }))


export const exploitationFormulaTypesUIHelper = (callback, keyword) => {

    let endpoint = FETCH_OPTIONS_ENDPOINT
    let query = {}
    if (!_.isEmpty(keyword)) {
      query = { q: keyword }
    }
  
    return new Promise((resolve, reject) =>
      makeExternalCall(HTTP_METHODS.GET, endpoint, {}, {}, query)
        .then(resp => {
          let result = {}
          if (_.isPlainObject(resp.data)) {
            result = [resp.data]
          } else {
            result = resp.data
            console.log(result)
          }
          resolve(callback(formatOptions(result)))
        })
        .catch(err => reject(err.response))
    )
  
}



/* export const EXPLOITATION_FORMULA_TYPES = { 
    1: "EXPLOITATION_FORMULA_TYPES.OWNER_TYPE",
    2: "EXPLOITATION_FORMULA_TYPES.TENANT_TYPE",
    3: "EXPLOITATION_FORMULA_TYPES.FREE_ACCOMMODATION_TYPE",
  }
  
export const exploitationFormulaTypesUIHelper = (intl) =>
    Object.keys(EXPLOITATION_FORMULA_TYPES).map((key) => ({
      value: parseInt(key),
      label: intl.formatMessage({ id: EXPLOITATION_FORMULA_TYPES[key] }),
    })) */
  
    