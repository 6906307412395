export const CREATE_SUBJECT_COMPETENCE_COMPETENCE_INIT = "subject_competence@CREATE_SUBJECT_COMPETENCE_INIT"
export const CREATE_SUBJECT_COMPETENCE_SUCCEDED = "subject_competence@CREATE_SUBJECT_COMPETENCE_SUCCEDED"
export const CREATE_SUBJECT_COMPETENCE_FAILED = "subject_competence@CREATE_SUBJECT_COMPETENCE_FAILED"

export const FETCH_SUBJECTS_COMPETENCES_INIT = "subject_competence@FETCH_SUBJECTS_COMPETENCES_INIT"
export const FETCH_SUBJECTS_COMPETENCES_SUCCEDED = "subject_competence@FETCH_SUBJECTS_COMPETENCES_SUCCEDED"
export const FETCH_SUBJECTS_COMPETENCES_FAILED = "subject_competence@FETCH_SUBJECTS_COMPETENCES_FAILED"

export const DISABLE_SUBJECT_COMPETENCE_INIT = "subject_competence@DISABLE_SUBJECT_COMPETENCE_INIT"
export const DISABLE_SUBJECT_COMPETENCE_SUCCEDED = "subject_competence@DISABLE_SUBJECT_COMPETENCE_SUCCEDED"
export const DISABLE_SUBJECT_COMPETENCE_FAILED = "subject_competence@DISABLE_SUBJECT_COMPETENCE_FAILED"

export const ENABLE_SUBJECT_COMPETENCE_INIT = "subject_competence@ENABLE_SUBJECT_COMPETENCE_INIT"
export const ENABLE_SUBJECT_COMPETENCE_SUCCEDED = "subject_competence@ENABLE_SUBJECT_COMPETENCE_SUCCEDED"
export const ENABLE_SUBJECT_COMPETENCE_FAILED = "subject_competence@ENABLE_SUBJECT_COMPETENCE_FAILED"

export const FETCH_SUBJECT_COMPETENCE_INIT = "subject_competence@FETCH_SUBJECT_COMPETENCE_INIT"
export const FETCH_SUBJECT_COMPETENCE_SUCCEDED = "subject_competence@FETCH_SUBJECT_COMPETENCE_SUCCEDED"
export const FETCH_SUBJECT_COMPETENCE_FAILED = "subject_competence@FETCH_SUBJECT_COMPETENCE_FAILED"

export const EDIT_SUBJECT_COMPETENCE_INIT = "subject_competence@EDIT_SUBJECT_COMPETENCE_INIT"
export const EDIT_SUBJECT_COMPETENCE_SUCCEDED = "subject_competence@EDIT_SUBJECT_COMPETENCE_SUCCEDED"
export const EDIT_SUBJECT_COMPETENCE_FAILED = "subject_competence@EDIT_SUBJECT_COMPETENCE_FAILED"



export const CLEAR_SUBJECT_COMPETENCE = "subject_competence@CLEAR_SUBJECT_COMPETENCE"
