
export const  SUBJECT = 1
export const SKILL = 2
export const OTHER = 3


export const TYPES_ACTIVITY = { 
  [SUBJECT]: "ACTIVITY.TYPE.SUBJECT",
  [SKILL]: "ACTIVITY.TYPE.SKILL",
  [OTHER]: "ACTIVITY.TYPE.OTHER",

}

export const activityTypeUIHelper = (intl) =>
  Object.keys(TYPES_ACTIVITY).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: TYPES_ACTIVITY[key] }),
  }))
