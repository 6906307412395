export const SUBJECT = 1
export const COMPETENCE = 2

export const SUBORCOMP = { 
  [SUBJECT]: "SUBJECT_COM.SUBJECT",
  [COMPETENCE]: "SUBJECT_COM.COMPETENCE",
}

export const subOrCompUIHelper = (intl) =>
  Object.keys(SUBORCOMP).map((value) => ({
    value: parseInt(value),
    label: intl.formatMessage({ id: SUBORCOMP[value] }),
  }))
    
  