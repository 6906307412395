import moment from "moment"


export const hoursUIHelper = (field, valueFormat = "HH", labelFormat = "HH") => {

  const minutes = []
  for (let time = moment().startOf("day"); time.isBefore(moment().endOf("day")); time.add(1, 'hour') ){
    if(field.name === 'afterMiddayStartHour' || field.name === 'afterMiddayEndHour') {
      if(time.locale('en').format(labelFormat) >= 12) {
        minutes.push({ label: time.locale('en').format(labelFormat), value: time.locale('en').format(valueFormat) })
      }
    }
    else if (field.name === 'beforeMiddayStartHour' || field.name === 'beforeMiddayEndHour') {
      if(time.locale('en').format(labelFormat) <= 12) {
        minutes.push({ label: time.locale('en').format(labelFormat), value: time.locale('en').format(valueFormat) })
      }
    }
    else {
      minutes.push({ label: time.locale('en').format(labelFormat), value: time.locale('en').format(valueFormat) })
    }
  }
  return minutes
}
