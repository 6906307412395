import _ from "lodash"

export const RETURN_TO_SCHOOL = {
  1: "EDUCATIONAL_SUPPORT.SPECIALITY.PRIMARY_SCHOOL",
  2: "EDUCATIONAL_SUPPORT.SPECIALITY.PREPERATORY_SCHOOL",
  3: "EDUCATIONAL_SUPPORT.SPECIALITY.HIGH_SCHOOL"
}

export const RETURN_TO_SCHOOL_TYPE = {
  1: "EDUCATIONAL_SUPPORT.SPECIALITY.PRIMARY_SCHOOL",
  2: "EDUCATIONAL_SUPPORT.SPECIALITY.PREPERATORY_SCHOOL",
  3: "EDUCATIONAL_SUPPORT.SPECIALITY.HIGH_SCHOOL"
}

export const OCCUPATIONAL_INTEGRATION = {
  1: "EDUCATIONAL_SUPPORT.SPECIALITY.ATFP",
  2: "EDUCATIONAL_SUPPORT.SPECIALITY.MILITARY",
  3: "EDUCATIONAL_SUPPORT.SPECIALITY.TOURISM",
  4: "EDUCATIONAL_SUPPORT.SPECIALITY.AGRICULTURE",
}

export const OTHER = {
  1: "EDUCATIONAL_SUPPORT.SPECIALITY.OTHER"
}


  export const specialityUIHelper = (callback, type, intl) =>{
    var res;
    if (type === 1)
    { res = {}
      res=  Object.keys(RETURN_TO_SCHOOL_TYPE).map((key) => ({
      value: parseInt(key),
      label: intl.formatMessage({ id: RETURN_TO_SCHOOL_TYPE[key] })
    }))} else if (type === 2)
    { res = {}
      res=  Object.keys(OCCUPATIONAL_INTEGRATION).map((key) => ({
      value: parseInt(key),
      label: intl.formatMessage({ id:OCCUPATIONAL_INTEGRATION[key] })
    }))}
    else if (type === 3)
    { res = {}
      res=  Object.keys(OTHER).map((key) => ({
      value: parseInt(key),
      label: intl.formatMessage({ id:OTHER[key] })
    }))}  
    return new Promise((resolve, reject) =>resolve(callback(res)))

  }


export const specialityDisplayUIHelper = ( edu_type, intl) =>{
    if (edu_type && edu_type === 1)
      {
        return Object.keys(RETURN_TO_SCHOOL_TYPE).map((key) => ({
          value: parseInt(key),
          label: intl.formatMessage({ id: RETURN_TO_SCHOOL_TYPE[key] })
        }))
    } else if (edu_type && edu_type === 2)
    {  return Object.keys(OCCUPATIONAL_INTEGRATION).map((key) => ({
      value: parseInt(key),
      label: intl.formatMessage({ id: OCCUPATIONAL_INTEGRATION[key] })
    }))
    }  else if (edu_type && edu_type === 3)
    { return Object.keys(OTHER).map((key) => ({
      value: parseInt(key),
      label: intl.formatMessage({ id: OTHER[key] })
    }))
    }

}

export const GO_BACK_SCHOOL_INDICATION = 1
export const OCCUPATIONAL_INTEGRATION_INDICATION = 2
export const OTHER_INDICATION = 3

export const SPECIALITY = { 
    [OCCUPATIONAL_INTEGRATION_INDICATION]: "EDUCATIONAL_SUPPORT.SPECIALITY.OCCUPATIONAL_INTEGRATION_INDICATION",
    [OTHER_INDICATION]: "EDUCATIONAL_SUPPORT.SPECIALITY.OTHER_INDICATION" ,
    [GO_BACK_SCHOOL_INDICATION]: "EDUCATIONAL_SUPPORT.SPECIALITY.GO_BAK_TO_SCHOOL" 
  }
