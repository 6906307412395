export const ABSENCE_CANCELLED = 1
export const ABSENCE_PRESENT = 2
export const ABSENCE_ABSENT = 3
export const ABSENCE_EXIT = 4

export const ABSENCE_STATUS = {
  [ABSENCE_CANCELLED]: "ABSENCE.STATUS.CANCEL",
  [ABSENCE_PRESENT]: "ABSENCE.STATUS.PRESENT",
  [ABSENCE_ABSENT]: "ABSENCE.STATUS.ABSENT",
  [ABSENCE_EXIT]: "ABSENCE.STATUS.EXIT",

}

export const ABSENCE_STATUS_COLOR = {
  [ABSENCE_CANCELLED]: "warning",
  [ABSENCE_PRESENT]: "success",
  [ABSENCE_ABSENT]: "danger",
  [ABSENCE_EXIT]: "warning"

}

export const absenceStatusUIHelper = (intl) =>
  Object.keys(ABSENCE_STATUS).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: ABSENCE_STATUS[key] }),
  }))
