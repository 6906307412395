export const ERROR_CODES = {
  404: "ERROR.CODE.NOT_FOUND",
  1100: "ERROR.CODE.BAD_CREDENTIALS",
  1101: "ERROR.CODE.INVALID_TOKEN",
  1102: "ERROR.CODE.BAD_TOKEN",
  1103: "ERROR.CODE.RESET_PASSWORD_FAILED",
  1104: "ERROR.CODE.USER_ALREADY_EXIST",
  1200: "ERROR.CODE.WRONG_PASSWORD",
  1301: "ERROR.CODE.DATE_RANGE_INVALID",
  1302: "ERROR.CODE.APPOINTMENT_TIME_IS_NOT_VALID",
  1303: "ERROR.CODE.FOLDER_UPDATE_FAILED",
  1304: "ERROR.CODE.FOLDER_ALREADY_EXIST",
  1305: "ERROR.CODE.FOLDER_NOT_RELATED_WITH_SCHOOL_DROPOUT",
  1306: "ERROR.CODE.FOLDER_CAN_NOT_BE_CLONED",
  1307: "ERROR.CODE.NO_DISPONIBLITY",
  1308: "ERROR.CODE.MULTIPLE_DISPONIBLITY",
  1309: "ERROR.CODE.YOU_NEED_TO_EDIT",
  1310: "ERROR.CODE.CLASSROOM_ALREADY_EXIST",
  1311: "ERROR.CODE.CLASSROOM_NOT_EXIST",
  1312: "ERROR.CODE.UNAVAILABILITY_PERIOD",
  1313: "ERROR.CODE.UNAVAILABILITY_PERIOD_INCLUDE_SAME_PERIOD",
  1314: "ERROR.CODE.DEFAULT_AVAILABILITY_ALREADY_EXIST",
  1315: "ERROR.CODE.MORNING_START_HOUR_END_HOUR",
  1316: "ERROR.CODE.AFTERNOON_START_HOUR_END_HOUR",
  1317: "ERROR.CODE.SAME_DAYS_START_DATE_END_DATE",
  1318: "ERROR.CODE.DAYS_NOT_EXIST_THIS_PERIOD",
  1319: "ERROR.CODE.DATE_EXIST_IN_UNAVAILABILITY",
  1320: "ERROR.CODE.USER_NOT_EXIST_AVAILABILITY",
  1321: "ERROR.CODE.DAYS_EXIST_AVAILABILITY_STRUCTURE",
  1322: "ERROR.CODE.SESSION_TIME_EXCEPTION_APPOINTMENT",
  1323: "ERROR.CODE.INTERVAL_BETWEEN_APPOINTMENT_EXCEPTION",
  1324: "ERROR.CODE.PERIOD_OR_DAYS_OR_HOURS_NOT_EXIST_SETTING",
  1325: "ERROR.CODE.DAYS_NOT_EXIST_DEFAULT_AVAILABILITY_SETTING",
  1326: "ERROR.CODE.TIME_NOT_EXIST_DEFAULT_AVAILABILITY_SETTING",
  1327: "ERROR.CODE.DAYS_NOT_EXIST_AVAILABILITY_SETTING",
  1328: "ERROR.CODE.DAYS_EXIST_AVAILABILITY_USER",
  1329: "ERROR.CODE.DEFAULT_DAYS_EXIST_USER",
  1330: "ERROR.CODE.DEFAULT_TIME_EXIST_USER",
  1331: "ERROR.CODE.BREAK_HOUR_APPOINTMENT",
  1332: "ERROR.CODE.START_HOUR_END_HOUR_EXCEPTION",
  1333:"ERROR.CODE.CURSUS_PERIOD",
  1334:"ERROR.CODE.APPOINTMENT_FOLDER_GROUP_EXIST",
  1335:"ERROR.CODE.PLANNING_START_DATE_END_DATE_EXCEPTION",
  1336:"ERROR.CODE.PLANNING_DATE_EXIST_IN_UNAVAILABILITY",
  1337: "ERROR.CODE.BREAK_HOUR_PLANNING_APPOINTMENT",
  1338: "ERROR.CODE.INTERVAL_BETWEEN_APPOINTMENT_PLANNING_EXCEPTION",
  1339: "ERROR.CODE.USER_NOT_EXIST_AVAILABILITY_PLANNING_EXCEPTION",
  1340: "ERROR.CODE.APPOINTMENT_TIME_IS_NOT_VALID_PLANNING_EXCEPTION",
  1341: "ERROR.CODE.DEFAULT_HOUR_EXCEPTION",
  1342: "ERROR.CODE.HOUR_EXCEPTION",
  1343: "ERROR.CODE.AVAILABILITY_TRAINER_EXCEPTION"



}

export const VALIDATION_ERROR_CODES = {
  required: "ERROR.CODE.VALIDATION.REQUIRED",
  unique: "ERROR.CODE.VALIDATION.UNIQUE",
  invalid: "ERROR.CODE.VALIDATION.INVALID",
  "null": "ERROR.CODE.VALIDATION.NULL",
  blank: "ERROR.CODE.VALIDATION.BLANK",
  max_length: "ERROR.CODE.VALIDATION.MAX_LENGTH",
  min_length: "ERROR.CODE.VALIDATION.MIN_LENGTH",
  invalid_choice: "ERROR.CODE.VALIDATION.INVALID_CHOICE",
  max_string_length: "ERROR.CODE.VALIDATION.MAX_STRING_LENGTH",
  min_value: "ERROR.CODE.VALIDATION.MIN_VALUE",
  max_value: "ERROR.CODE.VALIDATION.MAX_VALUE",
  max_whole_digits: "ERROR.CODE.VALIDATION.MAX_WHOLE_DIGITS",
  max_digits: "ERROR.CODE.VALIDATION.MAX_DIGITS",
  max_decimal_places: "ERROR.CODE.VALIDATION.MAX_DECIMAL_PLACES",
  not_a_list: "ERROR.CODE.VALIDATION.NOT_A_LIST",
  empty: "ERROR.CODE.VALIDATION.EMPTY",
  no_name: "ERROR.CODE.VALIDATION.NO_NAME",
  invalid_image: "ERROR.CODE.VALIDATION.INVALID_IMAGE",
  not_a_dict: "ERROR.CODE.VALIDATION.NOT_A_DICT",
  does_not_exist: "ERROR.CODE.VALIDATION.DOES_NOT_EXIST",
  incorrect_type: "ERROR.CODE.VALIDATION.INCORRECT_TYPE",
  incorrect_match: "ERROR.CODE.VALIDATION.INCORRECT_MATCH",
  no_match: "ERROR.CODE.VALIDATION.NO_MATCH"
}
