import _ from "lodash"

import { HTTP_METHODS } from "./../../../../../constants"
import { makeCall } from "./../../../../../helpers"
import { isRLTLang } from "../../../../../i18n"
import { store } from "./../../../../../configureStore"


const FETCH_ACTIVITY_ENDPOINT = "/api/activity/:param/type-activity/:folder_id"

const formatOptions = (options) => options.map((option) => (
  { label: isRLTLang() ? option.labelAr : option.labelFr , 
    value: option.id  }))


export const nameActivityUIHelper = (callback, activity_details, params) => {

  let endpoint
  if(activity_details) {
    if (!_.isInteger(activity_details['activityType']) || !activity_details['activityType']){
      callback([])
      return
    }
     endpoint = FETCH_ACTIVITY_ENDPOINT.replace(":param", activity_details['activityType']).replace(":folder_id", params['param'])
  }
 

  const { token } = store.getState().common.auth || {}

  let query = {}

  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, endpoint, {}, {'Authorization': `Bearer ${token.access}`}, query)
      .then(resp => {
        let result = {}
          if (_.isPlainObject(resp.data)) {
            result = [resp.data]
          } else {
            result = resp.data
          }
        resolve(callback(formatOptions(result)))
      })
      .catch(err => reject(err.response))
  )
}
