import {ACTIONS} from "./../constants"

const initialState ={
    subjects_competences: [],
    subject_competence: {},
    totalSize: 0,
    isFetching: false,
    isLoading: false,
    hasMore: true,
    success: {
      isDeactivated: false,
      isActivated: false,
      isCreated: false,
      isUpdated: false,
      isDeleted: false
    },
    error: null,    
}

export default (state = (initialState), action ) => {
    const  {payload, type} = action
    switch (type){

          case ACTIONS.CLEAR_SUBJECT_COMPETENCE: {
            return {
              ...state,
              success: initialState.success,
              totalSize: 0,
              error: null,
              isFetching: false,
              isLoading: false,
            }
          }

          case ACTIONS.CREATE_SUBJECT_COMPETENCE_INIT: {
            return { ...state, isLoading: true, error: null, success: initialState.success }
          }
          case ACTIONS.CREATE_SUBJECT_COMPETENCE_SUCCEDED: {
            return { ...state, success: { ...state.success, isCreated: true }, isLoading: false, error: null }
          }
          case ACTIONS.CREATE_SUBJECT_COMPETENCE_FAILED: {
            return { ...state, error: payload, isLoading: false, success: false }
          }


          case ACTIONS.FETCH_SUBJECTS_COMPETENCES_INIT: {
            return { ...state,  isFetching: true, error: null}
          }
          case ACTIONS.FETCH_SUBJECTS_COMPETENCES_SUCCEDED: {
            const {count , results} = payload
            return { ...state,  isFetching: false, subjects_competences: results , totalSize:count , error: null }
          }
          case ACTIONS.FETCH_SUBJECTS_COMPETENCES_FAILED: {
            return { ...state, isFetching: false, error: payload }
          }


          case ACTIONS.DISABLE_SUBJECT_COMPETENCE_INIT: {
            return { ...state, isLoading: true, success: initialState.success, error: null }
          }
          case ACTIONS.DISABLE_SUBJECT_COMPETENCE_SUCCEDED: {
            return { ...state, success: { ...state.success, isDeactivated: true }, isLoading: false, error: null }
          }
          case ACTIONS.DISABLE_SUBJECT_COMPETENCE_FAILED: {
            return { ...state, isLoading: false, error: payload }
          }

          case ACTIONS.ENABLE_SUBJECT_COMPETENCE_INIT: {
            return { ...state, isLoading: true, success: initialState.success, error: null }
          }
          case ACTIONS.ENABLE_SUBJECT_COMPETENCE_SUCCEDED: {
            return { ...state, success: { ...state.success, isActivated: true }, isLoading: false, error: null }
          }
          case ACTIONS.ENABLE_SUBJECT_COMPETENCE_FAILED: {
            return { ...state, isLoading: false, error: payload }
          }


          case ACTIONS.FETCH_SUBJECT_COMPETENCE_INIT: {
            return { ...state, isFetching: true, subject_competence: null, error: null }
          }
          case ACTIONS.FETCH_SUBJECT_COMPETENCE_SUCCEDED: {
            return { ...state, subject_competence: payload, isLoading: false, isFetching: false, error: null }
          }
          case ACTIONS.FETCH_SUBJECT_COMPETENCE_FAILED: {
            return { ...state, isFetching: false, isLoading: false, error: payload }
          }
          

          case ACTIONS.EDIT_SUBJECT_COMPETENCE_INIT: {
            return { ...state, isLoading: true, error: null, success: initialState.success }
          }
          case ACTIONS.EDIT_SUBJECT_COMPETENCE_SUCCEDED: {
            return { ...state, isLoading: false, error: null,subject_competence:payload, success: {...initialState.success, isUpdated:true}}
          }
          case ACTIONS.EDIT_SUBJECT_COMPETENCE_FAILED: {
            return { ...state,  isLoading: false, error: payload }
          }



          
          default: {
            return state
          }
    }
}