export const ACTIONS = require("./actions")
export const ENDPOINTS = require("./endpoints")

// filters
// export const FILTER_IN_ALL_FOLDERS_INIT_VALUE = 0
// export const FILTER_IN_ALL_FOLDERS = "external"


export const CLOSE_PARAM = "closed"
export const NOT_CLOSED = 1
export const CLOSED = 0
