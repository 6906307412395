import _ from "lodash"

import { HTTP_METHODS } from "./../../../../../constants"
import { makeExternalCall } from "./../../../../../helpers"
import { isRLTLang } from "../../../../../i18n"


const FETCH_OPTIONS_ENDPOINT = "/api/pa/options/list?list_number=24"

const formatOptions = (options) => options.map((option) => (
  { label: isRLTLang() ? option.valueAr : option.valueFr, value: option.rank }))


export const learningDisabilitiesUIHelper = (callback, keyword) => {

  let endpoint = FETCH_OPTIONS_ENDPOINT
  let query = {}
  if (!_.isEmpty(keyword)) {
    query = { q: keyword }
  }

  return new Promise((resolve, reject) =>
    makeExternalCall(HTTP_METHODS.GET, endpoint, {}, {}, query)
      .then(resp => {
        let result = {}
        if (_.isPlainObject(resp.data)) {
          result = [resp.data]
        } else {
          result = resp.data
        }
        resolve(callback(formatOptions(result)))
      })
      .catch(err => reject(err.response))
  )
}


const formatlearningDisabilitiesOptions = (options) => {
  let values = {}
  options.forEach((option) => {
      values[option.rank] = isRLTLang() ? option.valueAr : option.valueFr
  })
  return values;
}



export const getlearningDisabilitiesOptions = (callback, keyword) => {

  let endpoint = FETCH_OPTIONS_ENDPOINT
  let query = {}
  if (!_.isEmpty(keyword)) {
    query = { q: keyword }
  }
  
  return new Promise((resolve, reject) =>
    makeExternalCall(HTTP_METHODS.GET, endpoint, {}, {}, query)
      .then(resp => {
        let result = {}
        if (_.isPlainObject(resp.data)) {
          result = [resp.data]
        } else {
          result = resp.data
        }
        resolve(callback(formatlearningDisabilitiesOptions(result)))
      })
      .catch(err => reject(err.response))
  )
    }
 