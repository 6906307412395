export const EVALUATION_TYPE = { 
  1: "SKILLS_EVALUATION_D2C.EVALUATION_TYPE.INITIAL",
  2:"SKILLS_EVALUATION_D2C.EVALUATION_TYPE.INTERMEDIATE",
  3:"SKILLS_EVALUATION_D2C.EVALUATION_TYPE.FINAL",
  4:"SKILLS_EVALUATION_D2C.EVALUATION_TYPE.INTEGRATION"
}


export const evaluationTypeUIHelper = (intl) =>
  Object.keys(EVALUATION_TYPE).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: EVALUATION_TYPE[key] }),
  }))
