
import { HTTP_METHODS } from "./../../../../../constants"
import { makeCall } from "./../../../../../helpers"
import { store } from "./../../../../../configureStore"
import _ from "lodash"
import { isRLTLang } from "../../../../../i18n"
const FETCH_OPTIONS_ENDPOINT = "/api/subjects-competences"
const FOLDER_GROUP_LIST_ENDPOINT = "api/foldersD2c/group-details/:param"
export const folderGroupDetailsSubjectUIHelper = (dataCallback, settingPfi, params) => {
  const { token } = store.getState().common.auth || {}
  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, FOLDER_GROUP_LIST_ENDPOINT.replace(":param", params.param), {}, { 'Authorization': `Bearer ${token.access}` }, { active: 1 })
      .then(resp => {
        const result = []
        resp.data.cursusDetails.phases.map(phase => {
          phase.activitiesDetails.map(activity => {
            if (!result.find(el => el.id === activity.subjectSkillDetails.id)) { result.push(activity.subjectSkillDetails) }
          })
        })
        if (settingPfi && settingPfi?.subjectCompetence && !result.find(subj => subj.id === settingPfi.subjectCompetence))
          result.push(settingPfi.subjectCompetenceDetails)

        resolve(dataCallback(formatOptions(result)))
      }).catch((e) => reject(dataCallback([])))
  )
}

const formatOptions = (options) => options.map((option) => (
  { label: isRLTLang() ? option.labelAr : option.labelFr, value: option.id }))

export const subjectUIHelper = (dataCallback, params) => {
  const { token } = store.getState().common.auth || {}
  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, FETCH_OPTIONS_ENDPOINT, {}, { 'Authorization': `Bearer ${token.access}` }, { active: 1 })
      .then(async resp => {
        let subjectList = []
        const setSubjectList = (data) => {
          subjectList = data
        }

        await folderGroupDetailsSubjectUIHelper(setSubjectList, params)
        resolve(dataCallback(formatOptions(resp.data.results)))

      })
      .catch(() => reject(dataCallback([])))
  )
}
