// folder
export const CLEAR_FOLDER = "folder@CLEAR_FOLDER"
export const FILTER_APPOINTMENT = "folder@FILTER_APPOINTMENT"

export const FETCH_FOLDERS_INIT = "folder@FETCH_FOLDERS_INIT"
export const FETCH_FOLDERS_SUCCEDED = "folder@FETCH_FOLDERS_SUCCEDED"
export const FETCH_FOLDERS_FAILED = "folder@FETCH_FOLDERS_FAILED"




export const FOLDER_CREATE_APPOINTMENT_INIT = "folder@FOLDER_CREATE_APPOINTMENT_INIT"
export const FOLDER_CREATE_APPOINTMENT_SUCCEDED = "folder@FOLDER_CREATE_APPOINTMENT_SUCCEDED"
export const FOLDER_CREATE_APPOINTMENT_FAILED = "folder@FOLDER_CREATE_APPOINTMENT_FAILED"

export const CREATE_FOLDER_INIT = "folder@CREATE_FOLDER_INIT"
export const CREATE_FOLDER_SUCCEDED = "folder@CREATE_FOLDER_SUCCEDED"
export const CREATE_FOLDER_FAILED = "folder@CREATE_FOLDER_FAILED"

export const FETCH_FOLDER_INIT = "folder@FETCH_FOLDER_INIT"
export const FETCH_FOLDER_SUCCEDED = "folder@FETCH_FOLDER_SUCCEDED"
export const FETCH_FOLDER_FAILED = "folder@FETCH_FOLDER_FAILED"

export const DELETE_FOLDER_INIT = "folder@DELETE_FOLDER_INIT"
export const DELETE_FOLDER_SUCCEDED = "folder@DELETE_FOLDER_SUCCEDED"
export const DELETE_FOLDER_FAILED = "folder@DELETE_FOLDER_FAILED"

export const EDIT_FOLDER_INIT = "folder@EDIT_FOLDER_INIT"
export const EDIT_FOLDER_SUCCEDED = "folder@EDIT_FOLDER_SUCCEDED"
export const EDIT_FOLDER_FAILED = "folder@EDIT_FOLDER_FAILED"

export const DISABLE_FOLDER_INIT = "folder@DISABLE_FOLDER_INIT"
export const DISABLE_FOLDER_SUCCEDED = "folder@DISABLE_FOLDER_SUCCEDED"
export const DISABLE_FOLDER_FAILED = "folder@DISABLE_FOLDER_FAILED"

export const ENABLE_FOLDER_INIT = "folder@ENABLE_FOLDER_INIT"
export const ENABLE_FOLDER_SUCCEDED = "folder@ENABLE_FOLDER_SUCCEDED"
export const ENABLE_FOLDER_FAILED = "folder@ENABLE_FOLDER_FAILED"

export const CANCEL_TRANSFER_FOLDER_INIT = "folder@CANCEL_TRANSFER_FOLDER_INIT"
export const CANCEL_TRANSFER_FOLDER_SUCCEDED = "folder@CANCEL_TRANSFER_FOLDER_SUCCEDED"
export const CANCEL_TRANSFER_FOLDER_FAILED = "folder@CANCEL_TRANSFER_FOLDER_FAILED"

export const DISABLE_FOLDERS_INIT = "folder@DISABLE_FOLDERS_INIT"
export const DISABLE_FOLDERS_SUCCEDED = "folder@DISABLE_FOLDERS_SUCCEDED"
export const DISABLE_FOLDERS_FAILED = "folder@DISABLE_FOLDERS_FAILED"

export const ENABLE_FOLDERS_INIT = "folder@ENABLE_FOLDERS_INIT"
export const ENABLE_FOLDERS_SUCCEDED = "folder@ENABLE_FOLDERS_SUCCEDED"
export const ENABLE_FOLDERS_FAILED = "folder@ENABLE_FOLDERS_FAILED"

export const FETCH_FOLDER_CONTENT_INIT = "folder@FETCH_FOLDER_CONTENT_INIT"
export const FETCH_FOLDER_CONTENT_SUCCEDED = "folder@FETCH_FOLDER_CONTENT_SUCCEDED"
export const FETCH_FOLDER_CONTENT_FAILED = "folder@FETCH_FOLDER_CONTENT_FAILED"

export const EDIT_FOLDER_CONTENT_INIT = "folder@EDIT_FOLDER_CONTENT_INIT"
export const EDIT_FOLDER_CONTENT_SUCCEDED = "folder@EDIT_FOLDER_CONTENT_SUCCEDED"
export const EDIT_FOLDER_CONTENT_FAILED = "folder@EDIT_FOLDER_CONTENT_FAILED"

export const FETCH_APPOINTMENT_INIT = "folder@FETCH_APPOINTMENT_INIT"
export const FETCH_APPOINTMENT_SUCCEDED = "folder@FETCH_APPOINTMENT_SUCCEDED"
export const FETCH_APPOINTMENT_FAILED = "folder@FETCH_APPOINTMENT_FAILED"

export const FETCH_APPOINTMENTS_INIT = "folder@FETCH_APPOINTMENTS_INIT"
export const FETCH_APPOINTMENTS_SUCCEDED = "folder@FETCH_APPOINTMENTS_SUCCEDED"
export const FETCH_APPOINTMENTS_FAILED = "folder@FETCH_APPOINTMENTS_FAILED"

export const FETCH_AVAILABILITIES_INIT = "folder@FETCH_AVAILABILITIES_INIT"
export const FETCH_AVAILABILITIES_SUCCEDED = "folder@FETCH_AVAILABILITIES_SUCCEDED"
export const FETCH_AVAILABILITIES_FAILED = "folder@FETCH_AVAILABILITIES_FAILED"

export const CREATE_APPOINTMENT_INIT = "folder@CREATE_APPOINTMENT_INIT"
export const CREATE_APPOINTMENT_SUCCEDED = "folder@CREATE_APPOINTMENT_SUCCEDED"
export const CREATE_APPOINTMENT_FAILED = "folder@CREATE_APPOINTMENT_FAILED"

export const EDIT_APPOINTMENT_INIT = "folder@EDIT_APPOINTMENT_INIT"
export const EDIT_APPOINTMENT_SUCCEDED = "folder@EDIT_APPOINTMENT_SUCCEDED"
export const EDIT_APPOINTMENT_FAILED = "folder@EDIT_APPOINTMENT_FAILED"

export const EDIT_FOLDER_EXIT_INIT = "folder@EDIT_FOLDER_EXIT_INIT"
export const EDIT_FOLDER_EXIT_SUCCEDED = "folder@EDIT_FOLDER_EXIT_SUCCEDED"
export const EDIT_FOLDER_EXIT_FAILED = "folder@EDIT_FOLDER_EXIT_FAILED"

export const FETCH_FOLDER_EXTRA_DATA_INIT = "folder@FETCH_FOLDER_EXTRA_DATA_INIT"
export const FETCH_FOLDER_EXTRA_DATA_SUCCEDED = "folder@FETCH_FOLDER_EXTRA_DATA_SUCCEDED"
export const FETCH_FOLDER_EXTRA_DATA_FAILED = "folder@FETCH_FOLDER_EXTRA_DATA_FAILED"

export const EDIT_FOLDER_EXTRA_DATA_INIT = "folder@EDIT_FOLDER_EXTRA_DATA_INIT"
export const EDIT_FOLDER_EXTRA_DATA_SUCCEDED = "folder@EDIT_FOLDER_EXTRA_DATA_SUCCEDED"
export const EDIT_FOLDER_EXTRA_DATA_FAILED = "folder@EDIT_FOLDER_EXTRA_DATA_FAILED"

export const CREATE_FOLDER_EXTRA_DATA_INIT = "folder@CREATE_FOLDER_EXTRA_DATA_INIT"
export const CREATE_FOLDER_EXTRA_DATA_SUCCEDED = "folder@CREATE_FOLDER_EXTRA_DATA_SUCCEDED"
export const CREATE_FOLDER_EXTRA_DATA_FAILED = "folder@CREATE_FOLDER_EXTRA_DATA_FAILED"

export const CREATE_FOLDER_DOCUMENT_SUCCEDED = "folderDocument@CREATE_FOLDER_DOCUMENT_SUCCEDED"
export const EDIT_FOLDER_DOCUMENT_SUCCEDED = "folderDocument@EDIT_FOLDER_DOCUMENT_SUCCEDED"
export const DELETE_FOLDER_DOCUMENT_SUCCEDED = "folderDocument@DELETE_FOLDER_DOCUMENT_SUCCEDED"

export const DELETE_ORIENTATION_SUCCEDED = "orientation@DELETE_ORIENTATION_SUCCEDED"
export const CLOSE_ORIENTATION_SUCCEDED = "orientation@CLOSE_ORIENTATION_SUCCEDED"
export const CREATE_ORIENTATION_SUCCEDED = "orientation@CREATE_ORIENTATION_SUCCEDED"

export const UPDATE_AVAILABILITY_SETTINGS = "@availability@UPDATE_AVAILABILITY_SETTINGS"
export const LOAD_PREV_AVAILABILITIES = "@availability@LOAD_PREV_AVAILABILITIES"
export const LOAD_NEXT_AVAILABILITIES = "@availability@LOAD_NEXT_AVAILABILITIES"

export const FETCH_CPS_HISTORY_INIT = "folder@FETCH_CPS_HISTORY_INIT"
export const FETCH_CPS_HISTORY_SUCCEDED = "folder@FETCH_CPS_HISTORY_SUCCEDED"
export const FETCH_CPS_HISTORY_FAILED = "folder@FETCH_CPS_HISTORY_FAILED"

export const EXPORT_EMPLOYMENT_CONTRACT_INIT = "folder@EXPORT_EMPLOYMENT_CONTRACT_INIT"
export const EXPORT_EMPLOYMENT_CONTRACT_SUCCEDED = "folder@EXPORT_EMPLOYMENT_CONTRACT_SUCCEDED"
export const EXPORT_EMPLOYMENT_CONTRACT_FAILED = "folder@EXPORT_EMPLOYMENT_CONTRACT_FAILED"

export const EXPORT_INTERNAL_RULES_INIT = "folder@EXPORT_INTERNAL_RULES_INIT"
export const EXPORT_INTERNAL_RULES_SUCCEDED = "folder@EXPORT_INTERNAL_RULES_SUCCEDED"
export const EXPORT_INTERNAL_RULES_FAILED = "folder@EXPORT_INTERNAL_RULES_FAILED"