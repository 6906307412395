export const  ONE = "1"
export const TWO = "2"
export const THREE = "3"


export const EVALUATION_STATUS = {
    [ONE]: "⭐",
    [TWO]: "⭐⭐",
    [THREE]: "⭐⭐⭐"
  
  }
export const evaluationUIHelper = () =>
  Object.keys(EVALUATION_STATUS).map((key) => ({
    value: parseInt(key),
    label:  EVALUATION_STATUS[key] ,
  }))