
import { HTTP_METHODS } from "./../../../../../constants"
import { makeCall } from "./../../../../../helpers"
import { store } from "./../../../../../configureStore"


import _ from "lodash"
import { isRLTLang } from "../../../../../i18n"
const FETCH_OPTIONS_ENDPOINT = "/api/support_assessment_tools"


const formatOptions = (options) => options.map((option) => (
  { label: isRLTLang() ? option.labelAr : option.labelFr, value: option.id }))

export const assessementToolUIHelper = (callback, evaluationType) => {
  // let endpoint = evaluationType ? `${FETCH_OPTIONS_ENDPOINT}/${evaluationType}/filtered` : FETCH_OPTIONS_ENDPOINT
  const { token } = store.getState().common.auth || {}
  if (evaluationType)
    return new Promise((resolve, reject) =>
      makeCall(HTTP_METHODS.GET, `${FETCH_OPTIONS_ENDPOINT}/${evaluationType}/filtered`, {}, { 'Authorization': `Bearer ${token.access}` }, { active: 1 })
        .then(resp => {
          let result = {}
          if (_.isPlainObject(resp.data.results)) {

            result = [resp.data.results]

          } else {

            result = resp.data.results

          }
          resolve(callback(formatOptions(result)))

        }).catch(err => reject(err.response))

    )
} 