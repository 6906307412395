export const CURSUS_BACK_SCHOOL = 1
export const CURSUS_PROFFESSIONAL_INSERTION = 2
export const CURSUS_OTHER = 3



export const CURSUS_TYPE = {
  [CURSUS_BACK_SCHOOL]: "CURSUS_BACK_SCHOOL",
  [CURSUS_PROFFESSIONAL_INSERTION]: "CURSUS_PROFFESSIONAL_INSERTION",
  [CURSUS_OTHER]: "CURSUS_OTHER",
}

export const cursusTypeUIHelper = (intl) =>
  Object.keys(CURSUS_TYPE).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: CURSUS_TYPE[key] }),
  }))
