// cursus
export const FETCH_CURSES_INIT = "cursus@FETCH_CURSES_INIT"
export const FETCH_CURSES_SUCCEDED = "cursus@FETCH_CURSES_SUCCEDED"
export const FETCH_CURSES_FAILED = "cursus@FETCH_CURSES_FAILED"

export const CLEAR_CURSUS = "cursus@CLEAR_CURSUS"

export const CREATE_CURSUS_INIT = "cursus@CREATE_CURSUS_INIT"
export const CREATE_CURSUS_SUCCEDED = "cursus@CREATE_CURSUS_SUCCEDED"
export const CREATE_CURSUS_FAILED = "cursus@CREATE_CURSUS_FAILED"

export const FETCH_CURSUS_INIT = "cursus@FETCH_CURSUS_INIT"
export const FETCH_CURSUS_SUCCEDED = "cursus@FETCH_CURSUS_SUCCEDED"
export const FETCH_CURSUS_FAILED = "cursus@FETCH_CURSUS_FAILED"

export const EDIT_CURSUS_INIT = "cursus@EDIT_CURSUS_INIT"
export const EDIT_CURSUS_SUCCEDED = "cursus@EDIT_CURSUS_SUCCEDED"
export const EDIT_CURSUS_FAILED = "cursus@EDIT_CURSUS_FAILED"

export const DEACTIVATE_CURSUS_INIT = "cursus@DEACTIVATE_CURSUS_INIT"
export const DEACTIVATE_CURSUS_SUCCEDED = "cursus@DEACTIVATE_CURSUS_SUCCEDED"
export const DEACTIVATE_CURSUS_FAILED = "cursus@DEACTIVATE_CURSUS_FAILED"

export const ACTIVATE_CURSUS_INIT = "cursus@ACTIVATE_CURSUS_INIT"
export const ACTIVATE_CURSUS_SUCCEDED = "cursus@ACTIVATE_CURSUS_SUCCEDED"
export const ACTIVATE_CURSUS_FAILED = "cursus@ACTIVATE_CURSUS_FAILED"


export const DELETE_CURSUS_INIT = "cursus@DELETE_CURSUS_INIT"
export const DELETE_CURSUS_SUCCEDED = "cursus@DELETE_CURSUS_SUCCEDED"
export const DELETE_CURSUS_FAILED = "cursus@DELETE_CURSUS_FAILED"
