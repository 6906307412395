export const PA_VERSION = "v0.9"

export const APP_VERSION = "v0.3"

export const FR = "fr"
export const AR = "ar-tn"
export const EN = "en"

export const ARCHIVED = 0
export const NOT_ARCHIVED = 1
export const ARCHIVED_PARAM = "active"
export const IS_ACTIVE_INIT_VALUE = 1
export const IS_ACTIVE = "is_active"
export * from "./API"
export * from "./ErrorCode"
export * from "./Permissions"


export const MODULES_PERMISSIONS = require("./ModulesPermissions")
