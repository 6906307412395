export const CLASSROOM_STATUS_CODE_ONE = 1
export const CLASSROOM_STATUS_CODE_TWO = 2

export const CLASSROOM_STATUS = {
    [CLASSROOM_STATUS_CODE_ONE]: "CLASSROOM.STATUS.RESERVED",
    [CLASSROOM_STATUS_CODE_TWO]: "CLASSROOM.STATUS.NOT_RESERVED"
}

export const CLASSROOM_STATUS_COLOR = {
    1: "danger",
    2: "success",
}
export const classroomStatusUIHelper = (intl) =>
    Object.keys(CLASSROOM_STATUS).map((key) => ({
        value: parseInt(key),
        label: intl ? intl.formatMessage({ id: CLASSROOM_STATUS[key] }) : CLASSROOM_STATUS[key]
    }))
