export const CLASSROOM_STATE_CODE_ONE = 1
export const CLASSROOM_STATE_CODE_TWO = 2


export const CLASSROOM_STATE = {
    [CLASSROOM_STATE_CODE_ONE]: "CLASSROOM.STATE.TRAVAUX",
    [CLASSROOM_STATE_CODE_TWO]: "CLASSROOM.STATE.DISPONIBLE",

}

export const classroomStateUIHelper = (intl) =>
    Object.keys(CLASSROOM_STATE).map((key) => ({
        value: parseInt(key),
        label:  intl.formatMessage({ id: CLASSROOM_STATE[key] })
    }))