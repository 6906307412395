export const FOLDER_STATE = {
  1: "FOLDER.STATE.A_TRAITER",
  2: "FOLDER.STATE.ACCEPT",
  3: "FOLDER.STATE.REJECT",
  4: "FOLDER.STATE.PANDING"
}
export const FOLDER_STATE_ACCEPT = 2
export const FOLDER_STATE_REJECT = 3

export const FOLDER_STATE_COLOR = {
  1: "warning",
  2: "primary",
  3: "success"
}


export const transfaredfolderStateUIHelper = (intl) =>
  Object.keys(FOLDER_STATE).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: FOLDER_STATE[key] }),
  }))
