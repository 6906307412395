// activity
export const FETCH_ACTIVITIES_INIT = "activity@FETCH_ACTIVITIES_INIT"
export const FETCH_ACTIVITIES_SUCCEDED = "activity@FETCH_ACTIVITIES_SUCCEDED"
export const FETCH_ACTIVITIES_FAILED = "activity@FETCH_ACTIVITIES_FAILED"

export const CLEAR_ACTIVITY = "activity@CLEAR_ACTIVITY"

export const CREATE_ACTIVITY_INIT = "activity@CREATE_ACTIVITY_INIT"
export const CREATE_ACTIVITY_SUCCEDED = "activity@CREATE_ACTIVITY_SUCCEDED"
export const CREATE_ACTIVITY_FAILED = "activity@CREATE_ACTIVITY_FAILED"

export const FETCH_ACTIVITY_INIT = "activity@FETCH_ACTIVITY_INIT"
export const FETCH_ACTIVITY_SUCCEDED = "activity@FETCH_ACTIVITY_SUCCEDED"
export const FETCH_ACTIVITY_FAILED = "activity@FETCH_ACTIVITY_FAILED"

export const EDIT_ACTIVITY_INIT = "activity@EDIT_ACTIVITY_INIT"
export const EDIT_ACTIVITY_SUCCEDED = "activity@EDIT_ACTIVITY_SUCCEDED"
export const EDIT_ACTIVITY_FAILED = "activity@EDIT_ACTIVITY_FAILED"

export const DEACTIVATE_ACTIVITY_INIT = "activity@DEACTIVATE_ACTIVITY_INIT"
export const DEACTIVATE_ACTIVITY_SUCCEDED = "activity@DEACTIVATE_ACTIVITY_SUCCEDED"
export const DEACTIVATE_ACTIVITY_FAILED = "activity@DEACTIVATE_ACTIVITY_FAILED"

export const ACTIVATE_ACTIVITY_INIT = "activity@ACTIVATE_ACTIVITY_INIT"
export const ACTIVATE_ACTIVITY_SUCCEDED = "activity@ACTIVATE_ACTIVITY_SUCCEDED"
export const ACTIVATE_ACTIVITY_FAILED = "activity@ACTIVATE_ACTIVITY_FAILED"


export const DELETE_ACTIVITY_INIT = "activity@DELETE_ACTIVITY_INIT"
export const DELETE_ACTIVITY_SUCCEDED = "activity@DELETE_ACTIVITY_SUCCEDED"
export const DELETE_ACTIVITY_FAILED = "activity@DELETE_ACTIVITY_FAILED"
