import _ from "lodash"
import { HTTP_METHODS } from "./../../../../constants"
import { makeCall } from "./../../../../helpers"
import { store } from "./../../../../configureStore"
import {isRLTLang} from "../../../../i18n"
import { INTERNE } from "../folder/folder"


const FETCH_USERS_ENDPOINT = "/api/users/all"


const formatUsers = (users) => users.map((user) => ({ 
    label: !isRLTLang() ? `${user.firstName} ${user.lastName}` : `${user.firstNameAr} ${user.lastNameAr}`, 
    value: user.id,
    ...user
  }))

export const observerUIHelper = (callback, user, animatorType) => {
      if (animatorType === INTERNE && !user) {
          callback([])
          return
      }
        const { token } = store.getState().common.auth || {}

        return new Promise((resolve, reject) =>
          makeCall(HTTP_METHODS.GET, FETCH_USERS_ENDPOINT, {}, {'Authorization': `Bearer ${token.access}`}, {} )
          .then(resp => {
            console.log(resp.data)
            console.log(user)

            let result = resp.data.filter(item => item.id !== user)
            resolve(callback(formatUsers( result )))
        
        })
          .catch(() => reject(callback([])))
        )
  
  }







