export const ABSENCE_SHEET_COMPLETED = 1
export const ABSENCE_SHEET_NOT_COMPLETED = 0
export const ABSENCE_SHEET_CLOSED = 2
export default ABSENCE_SHEET_CLOSED
export const ABSENCE_SHEET_STATUS = {
  [ABSENCE_SHEET_COMPLETED]: "ABSENCE_SHEET.STATUS.COMPLETED",
  [ABSENCE_SHEET_NOT_COMPLETED]: "ABSENCE_SHEET.STATUS.NOT_COMPLETED",
  [ABSENCE_SHEET_CLOSED]: "ABSENCE_SHEET.STATUS.CLOSED",

}

export const ABSENCE_SHEET_STATUS_COLOR = {
  [ABSENCE_SHEET_COMPLETED]: "success",
  [ABSENCE_SHEET_NOT_COMPLETED]: "primary",
  [ABSENCE_SHEET_CLOSED]: "info",

}

export const absenceSheetStatusUIHelper = (intl) =>
  Object.keys(ABSENCE_SHEET_STATUS).map((key) => ({
    value: parseInt(key),
    label: intl.formatMessage({ id: ABSENCE_SHEET_STATUS[key] }),
  }))
