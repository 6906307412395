export const INTERNE = 1
export const EXTERNE = 2


export const TYPE_USER = {
    [INTERNE]: "TYPE.USER.INTERNE",
    [EXTERNE]: "TYPE.USER.EXTERNE"
}


export const typeUserUIHelper = (intl) =>
  Object.keys(TYPE_USER).map((key) => ({
      value: parseInt(key),
      label: intl.formatMessage({ id: TYPE_USER[key] }),
  }))
