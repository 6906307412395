


import { HTTP_METHODS } from "./../../../../../constants"
import { makeCall } from "./../../../../../helpers"
import { store } from "./../../../../../configureStore"
import _ from "lodash"
import { isRLTLang } from "../../../../../i18n"
import { localeData } from "moment"
const FETCH_OPTIONS_ENDPOINT = "/api/subjects-competences"
const PFI_LIST_ENDPOINT = "/api/folders/:param/setting-pfi/list"
const formatOptions = (options) => options.map((option) => (
  { label: isRLTLang() ? option.labelAr : option.labelFr, value: option.id }))

export const pfiListUIHelper = (dataCallback, params) => {
  const { token } = store.getState().common.auth || {}

  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, PFI_LIST_ENDPOINT.replace(":param", params.param), {}, { 'Authorization': `Bearer ${token.access}` }, { active: 1 })
      .then(resp => {
        let list
        if (params.settingPfiParam)
          list = resp.data.results.filter(el => el.id != params.settingPfiParam)
        else
          list = resp.data.results
        resolve(dataCallback(list.map(pfi => pfi.subSubjectCompetence)) || [])
      })
      .catch(() => reject(dataCallback([])))
  )
}

export const subSubjectUIHelper = (dataCallback, subject, params) => {
  const { token } = store.getState().common.auth || {}
  let endpoint = subject ? `${FETCH_OPTIONS_ENDPOINT}/${subject}` : FETCH_OPTIONS_ENDPOINT

  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, endpoint, {}, { 'Authorization': `Bearer ${token.access}` }, { active: 1 })
      .then(async resp => {
        let usedSubSubject = []
        const setUsedSubSubject = (data) => {
          usedSubSubject = data
        }
        await pfiListUIHelper(setUsedSubSubject, params)
        resolve(dataCallback(formatOptions(resp.data.subSubjectCompetenceSet.filter(el => !usedSubSubject.includes(el.id)))) || [])
        // resolve(dataCallback(formatOptions(resp.data.subSubjectCompetenceSet)) || [])
      })
      .catch(() => reject(dataCallback([])))
  )
}

